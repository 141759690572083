export const themeColor = {
	white: "#FFFFFF",
    black: "#000000",
    yellow: "#FCEE0A",
    red: "#FD0130",
    cyan: "#02D8F3",
    lightGray: "#CBCBCB",
    darkGray: "#757575",
    transparent: "#00000000",
    gwentLightGreen: '#2BF5B9',
    disabledBtn: "#093125",
    brown: "#7E7C69",
    turquiseLight: "#32EEE2",
    veryDarkBlue: "#001624",
    panelBlue: "#283546",
    lightBlue: "#4C5F76",
};

export const LightenDarkenColor = (col, amt) => {
  
    let usePound = true;
  
    if (col[0] === "#") {
        col = col.slice(1);
        usePound = true;
    }
 
    const num = parseInt(col,16);
 
    let r = (num >> 16) + amt;
 
    if (r > 255) r = 255;
    else if  (r < 0) r = 0;
 
    let b = ((num >> 8) & 0x00FF) + amt;
 
    if (b > 255) b = 255;
    else if  (b < 0) b = 0;
 
    let g = (num & 0x0000FF) + amt;
 
    if (g > 255) g = 255;
    else if (g < 0) g = 0;
 
    return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
}