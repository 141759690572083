import React from 'react';
import { BottomBarContainer } from './styles';

const BottomBar = ({children}) => {
  return(
    <BottomBarContainer>{children}</BottomBarContainer>
  )
}

export default BottomBar;
