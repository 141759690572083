import React from 'react';
import { Modal } from './styles';

const ModalComponent = ({ children, open = false, close }) => {
  return (
    <Modal
      open={open}
      onClose={() => close(false)}
      disablePortal={true}
    >
      {children}
    </Modal>
  )
}

export default ModalComponent;
