import React from 'react';
import {ContentContainer} from './styles';

const ModalBottom = ({children}) => {
  return(
    <ContentContainer>
      {children}
    </ContentContainer>
  )
}

export default ModalBottom;
