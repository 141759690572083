import styled from "@emotion/styled";

export const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    p {
      font-size: 20px;
      line-height: 24px;
      color: #333;
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
      padding: 16px 0;
    }
  }

  &.title__container {
    padding: 4px 24px 0;
    margin-bottom: 16px;
  }

  &.columns-title__container {
    margin-bottom: 15px;

    .file__text,
    .videotype__text {
      padding: 0 24px;

      p {
        font-size: 15px;
        line-height: 18px;
        font-family: 'Roboto', sans-serif;
        color: #A3A3A3;
        font-weight: 300;
      }
    }

    .videotype__text {
      justify-content: flex-start;
      width: 176px;
    }
  }

  &.buttons__container {
    padding: 16px 24px 24px;
    border-top: 1px solid #e9e9e9;

    .btn__add-next button {
      background-color: transparent;
      border: 0;
      outline: none;
      font-size: 15px;
      line-height: 18px;
      font-family: 'Roboto', sans-serif;
      font-weight: 300;
      color: #2F80ED;
      padding: 0;
      &:disabled {
        opacity: .3;
      }
    }

    .btn__upload-now {
      margin-left: auto;

      button {
        &:disabled {
          color: #333;
          background-color: #EFEFEF;
        }
      }
    }

    .btn__big button {
      background-color: #2F80ED;
      color: #fff;
      font-size: 16px;
      line-height: 19px;
      font-weight: 500;
      text-align: right;
      padding: 12px 24px;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
      border: 0;
      margin-right: 16px;
      position: relative;
      height: 43px;
      min-width: 72px;
      outline: none;
      font-family: 'Roboto', sans-serif;
      margin-right: 0;
    }
  }

  &.upload-item {
    padding: 16px 24px;
    border-top: 1px solid #e9e9e9;
    justify-content: flex-end;

    .upload-item__name {
      margin-right: auto;
    }

    .upload-item__type {
      font-size: 15px;
      line-height: 18px;
      font-weight: 400;
      opacity: 0.2;
      color: #000000;
      margin-left: 4px;
    }

    .upload-item__remove {
      margin-left: 16px;
    }

    .form-input {
      border: 1px solid #e9e9e9;
      padding: 4px 16px;
      border-radius: 4px;
    }
    .select-input--uploads {
      width: 142px;
    }
  }

  .img-thumbnail {
    display: none;
  }
`;

export const InputFile = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 358px;
  margin-right: auto;

  input {
    display: none;
    &[readOnly] + label {
      pointer-events: none;
      cursor: default;
    }
  }

  label {
    font-size: 15px;
    line-height: 18px;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;

    p {
      color: #000;
    }

    span {
      color: #2F80ED;
    }
  }
`

export const VideoContent = styled.div`
  width: 100%;
  max-width: 400px;
  position: absolute;
  left: -9999999999999px;
  height: 0;
  video {
    max-width: 100%;
  }
  opacity: 0;
  visibility: hidden;
`

