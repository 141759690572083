import { Auth, Storage } from 'aws-amplify';
import { store } from '../store';
import { useEffect, useState, useContext, useRef } from 'react';
import { HeadObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { getFileByKey, guessMediaType} from '../helpers';

export const useMediaUrl =  (key, isPrivate, expires = 3600) => {
    const { state, dispatch , config} = useContext(store);
    const expiration = Math.max(expires, 900);
    const refreshSafeMargin = 100;
    const [url, setUrl] = useState();

    const refMounted = useRef();
    useEffect(() => {
        refMounted.current = true;
        return () => {
            refMounted.current = false;
        }
    }, [])

    const updateUrl = async () => {
        const signedUrl = await config.getSignedUrl(key, isPrivate, expiration);
   //     console.log("[useMediaUrl updateUrl]", key, isPrivate, url);
        if (refMounted.current) setUrl(signedUrl);   
    }

    useEffect(() => {        
        updateUrl();
        const interval = setInterval(updateUrl, 1000 * (expiration - refreshSafeMargin))
        return () => {
            clearInterval( interval);
        }
    }, [key, isPrivate, state.mediaUpdate])

    useEffect(() => {        
       if (!state.mediaUpdate) return;
        updateUrl();
    }, [state.mediaUpdate])

    useEffect(() => {
        if (!url) return;
//       console.log("[useMediaUrl change]", key, isPrivate, url);
    }, [url, key, isPrivate])
    return url;
}

export const useMedia = (enabled, region, bucket) => {
    const { state, dispatch } = useContext(store);
    
    const loadMedia = async () => {
        const media = [];

        const credentials = await Auth.currentCredentials();
        const s3client = new S3Client({
            region: region,
            credentials: Auth.essentialCredentials(credentials),
        });

        const fetchDuration = async (key) => {
            const headObjectCommand = new HeadObjectCommand({
                Bucket: bucket,
                Key: key
            });
            const headResponse = await s3client.send(headObjectCommand);
  //          console.log('Metadata', key, headResponse.Metadata);
            if (headResponse.Metadata && headResponse.Metadata.duration) {
                return parseInt(headResponse.Metadata.duration)
            }
            return undefined;
        }

        const getDuration = async (key, isPrivate) => {
            const file = getFileByKey(key, isPrivate, state.mediaList);
            if (file && file.duration)
                return file.duration;
            return await fetchDuration(`${isPrivate ? `private/${credentials.identityId}` : `public`}/${key}`);
        }

        const precessList = (isPrivate) => async result => {
     //       console.log(`Storage.list [isPrivate:${isPrivate}]`, result);
            for (const { size, key } of result) {
                if (size) {
                    const type = guessMediaType(key);
                    const needsDuration = type === 'video';
                    const item = getFileByKey(key, isPrivate, state.mediaList);
              
                    if (item && (!needsDuration || item.duration)) {
                        media.push(item);
                    } else {
                        const duration = needsDuration ? await getDuration(key, isPrivate) : undefined;
                        media.push({
                            type,
                            key,
                            size,
                            duration,
                            date: Date.now(),
                            private: isPrivate
                        });
                    }
                }
            }
        }
        await Storage.list('', { level: 'private' }).then(precessList(true))
        await Storage.list('', { level: 'public' }).then(precessList(false))
        dispatch({ type: 'setMediaList', item: media })
    }

    useEffect(() => {
        if (!enabled) return;
        if (!state.mediaUpdate) return;
        loadMedia();
    }, [dispatch, state.mediaLoaded, enabled, state.mediaUpdate])

}

export default useMedia;