import styled from '@emotion/styled';

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;
  opacity: ${props => props.blocked ? '0.5' : '1'};
  pointer-events: ${props => props.blocked ? 'none' : 'all'};
`;

export const TextTypeButton = styled.p`
  display: block;
  font-weight: 700;
  cursor: pointer;
`

export const ButtonTypeButton = styled.button`
  outline: none;
  border: 0;
  cursor: pointer;

  &:disabled {
    pointer-events: none;
  }
`
