import { useState, useEffect } from "react";
/**
 *
 * @param {String} key The key to store our data to
 * @param {String} initialValue The default value to return in case the data doesn't exist
 */
export const useLocalStorage = (key, initialValue) => {
    const isBrowser = typeof window !== "undefined";
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const getInitState = () => {
        try {
            // Get from local storage by key
            const item = isBrowser ? window.localStorage.getItem(key) : null;
            // Parse stored json or if none return initialValue
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            // If error also return initialValue
            console.log(error);
            return initialValue;
        }
    }

    const [storedValue, setStoredValue] = useState(getInitState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => setStoredValue(getInitState()), [key]);

    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = value => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore =
                value instanceof Function ? value(storedValue) : value;
            // Save state
            setStoredValue(valueToStore);
            // Save to local storage
            isBrowser && window.localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            // A more advanced implementation would handle the error case
            console.log(error);
        }
    };

    return [storedValue, setValue];
}
