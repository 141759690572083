import styled from "@emotion/styled";

export const Container = styled.div`
  display: block;
  width: 100%;
  min-height: calc(100vh - 72px);
  background-color: #e5e5e5;
  &.form-view {
    min-height: auto;
  }
  .btn--loading {
    height: 48px;
  }
  .btn__big {
    background-color: #2F80ED;
    color:#fff;
    font-size: 20px;
    line-height: 23px;
    font-weight: 500;
    text-align: right;
    padding: 14px 22px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
    border: 0;
    margin-right: 16px;
    position: relative;
    min-width: 72px;
    outline: none;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
    &.btn--inactive {
      text-decoration: none;
      opacity: .3;
      cursor: default;
    }
    
  }
  .btn__return {
    background-color: transparent;
    outline: none;
    border: 0;
    margin-left: 36px;
    margin-right: 54px;
    cursor: pointer;
  }


  .btn__upload {
    background-color: #fff;
    color: #2F80ED;
    margin-right: 48px;
    cursor:pointer;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 88px;
  background-color: #fff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 1;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  &.flex__start {
    justify-content: flex-start;
  }
`;

export const ContentContainer = styled.div`

  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-flow: row wrap;
  height: 100%;
  padding:8px 0 108px;


  .btn__text-variant,
  .btn__video-variant {
    height: 52px;
    background: #ffffff;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    p {
      color: #2a7bda;
      font-size: 20px;
      line-height: 24px;
      font-weight: 500;
      width: 100%;
      height: 100%;
      display:flex;
      align-items: center;
      justify-content: center;
    }
  }
  .btn__add-more {
    width: 67px;
  }
  .btn__no-text {
    margin-left: 16px;
    padding: 0 24px;
    p {
      font-size: 16px;
    }
  }

  .btn__add-segment {
    justify-content: flex-start;
    
    p {
      font-size: 16px;
      line-height: 19px;
      font-weight: 500;
      color: #2F80ED;
      padding: 15px 24px;
      width: 100%;
      height: 100%;
      display:flex;
      align-items: center;
      justify-content: flex-start;
    }
  }

  .form-input {
    height: 44px;
    border: 1px solid #a7a7a7;
    width: 100%;
    border-radius: 4px;
    &.select-input--error {
      border-color: #ff0000;
    }
    input {
      padding: 0 16px;
      height: 100%;
      font-size: 15px;
      font-weight: 400;
      line-height: 18px;
    }
    #fontColor {
      padding-left: 32px;
    }
    &.invalid {
      border-color: #ff0000;
    }
    &--adornment {
      position: absolute;
      left: 20px;
    }
    > div {
      height: 100%;
      &:after,
      &:before {
        display: none;
      }
    }
    .select-input {
      height: 100%;
      padding: 0 16px;
      display:flex;
      align-items: center;
      font-size: 15px;
      font-weight: 400;
      line-height: 18px;
    }

  }

`;

export const SingleVariant = styled.div`
  background-color: #fff;
  padding: 24px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  width: 100%;
  margin-bottom: 16px;
  opacity: ${props => props.loadingState ? '.5' : '1'};
  pointer-events: ${props => props.loadingState ? 'none' : 'all'};
  border-radius: 4px;
  * {
     pointer-events: ${props => props.loadingState ? 'none' : 'all'};
  }
  &.text-variants {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: row wrap;
    width: 100%;
    margin-bottom: 16px;
    padding: 16px;
    &.text-variants--no-text,
    &.text-variants--no-audio {
      p {
        font-size: 15px;
        line-height: 18px;
        color: #000;
      }

    }
    &.text-variants--no-text {
      
      .form-input {
        display: none;
      }
    }
    &.text-variants--no-audio {
      padding-top: 3px;
      padding-bottom: 3px;
      .form-input {
        border: 0;
        input {
          padding: 0;
          &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: #000;
            opacity: 1;
          }
          &::-moz-placeholder { /* Firefox 19+ */
            color: #000;
            opacity: 1;
          }
          &:-ms-input-placeholder { /* IE 10+ */
            color: #000;
            opacity: 1;
          }
          &:-moz-placeholder { /* Firefox 18- */
            color: #000;
            opacity: 1;
          }
        }
      }
    }
    .form-input {
      max-width: calc(100% - 32px);
    }
  }
  &.single-variant__video {
    padding: 0;
    padding-top: 8px;
  }
`;

export const OptionContainer = styled.div`
  max-width: 33%;
  flex: 1 0 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 40px;
  &.video-variants {
    max-width: 920px;
  }
  &.text-variants {
    max-width: 346px;
  }
  &.text-settings {
    max-width: 800px;
    width: 100%;
    background-color: #fff;
    padding: 16px 20px;
    .text-settings__header {
      flex: 1 0 100%;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
      margin-bottom: 26px;
      p {
        width: auto;
        display: inline-block;
        margin: 0;
      }
    }
    .text-settings__content {
      box-shadow: none;
      display:flex;
      flex-flow: row wrap;
      margin: 0 auto;
      padding: 0;
      .form-input {
        height: 44px;
        border: 1px solid #a7a7a7;
        width: 100%;
        input {
          padding: 0 16px;
          height: 100%;
          font-size: 15px;
          font-weight: 400;
          line-height: 18px;
        }
        #fontColor {
          padding-left: 32px;
        }
        &.invalid {
          border-color: #ff0000;
        }
        &--adornment {
          position: absolute;
          left: 20px;
        }
        > div {
          height: 100%;
          &:after,
          &:before {
            display: none;
          }
        }
        .select-input {
          height: 100%;
          padding: 0 16px;
          display:flex;
          align-items: center;
          font-size: 15px;
          font-weight: 400;
          line-height: 18px;
        }
        &.additional-info {
          margin-bottom: 18px;
          > p{
            position: absolute;
            bottom: -20px;
          }
        }
    }
  }
`;

export const VariantTitle = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #333;
  width: 100%;
  margin-bottom: 16px;
`;

export const HeaderTitle = styled.h2`
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #000;
  margin-left: 32px;
`;

export const TextSettingsItem = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
  margin-bottom: 16px;
`;

export const TextSettingsLeft = styled.div`
  width: calc(100% - 160px);
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  padding-right: 16px;
`;

export const TextSettingsRight = styled.div`
  width: 160px;
`;

export const CloseButton = styled.div`
  display: inline-flex;
  height: 13px;
  width: 13px;
  position: relative;
  cursor: pointer;
  &:after,
  &:before {
    content: '';
    height: 2px;
    width: 100%;
    background-color: #949494;
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
  }
  &:after {
    transform: rotate(45deg);
  }
  &:before {
    transform: rotate(-45deg);
  }

  &.disabled {
    opacity: .2;
  }
`

export const SingleSegment = styled.div`
  display:flex;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: row wrap;
  border-bottom: 1px solid #e9e9e9;
  padding: 16px 24px;

  .select-input--segment {
    max-width: 300px;
    margin-right: 10px;
    width: 100%;
    .select-input {
      display:block;
      position: absolute;
      box-sizing:border-box;
      padding: 11px 16px;
    }
  }
  .select-input--anchor {
    flex: 1 0 100%;
    width: 100%;
  }

  .select-input__form-controll {
    flex: 1 0 auto;
    margin-right: 16px;
  }
  .close-btn {
    margin-top: 13px;
  }
  }
`

export const SegmentTitles = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row wrap;
  width: 100%;
  padding: 8px 24px 16px;
  border-bottom: 1px solid #e9e9e9;
`

export const RollTitle = styled.div`
  max-width: 300px;
  width: 100%;
  color: #A3A3A3;
  font-size: 15px;
  line-height: 18px;
  font-weight: 400;
  margin-right: 10px;
`

export const AnchoringTitle = styled.div`
  flex: 1 0 auto;
  color: #A3A3A3;
  font-size: 15px;
  line-height: 18px;
  font-weight: 400;
`

export const AddBRoll = styled.p`
  display:flex;
  width: 100%;
  text-align: left;
  font-size: 15px;
  color: #2F80ED;
  line-height: 18px;
  font-weight: 500;
  margin-top: 12px;
  cursor: pointer;

`
export const VariantsBContainer = styled.div`
  display: inline-flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  flex-flow: row wrap;
  width: 100%;
  max-width: 300px;
  .select-input--segment-b {
    margin-bottom: 10px;
  }
`

export const VariantBContainer = styled.div`
  display: flex;
  alitn-items: center;
  justify-content: flex-start;
  flex-flow: row wrap;
  position: relative;
  width: 100%;
  &:first-of-type {
    .remove-video-b {
      display: none;
    }
  }
  &:hover {
    .remove-video-b {
      right: -12px;
      opacity: 1;
    }
  }
  .remove-video-b {
    position: absolute;
    right: 0px;
    opacity: 0;
    top: 50%;
    margin-top: -14px;
    transition: all .2s ease-in-out;
  }
`

export const AudioVariantsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: row wrap;
  margin-top: 42px;
  max-width: 100%;
  .select-input--audio {
    max-width: 200px;
    width: 100%;
    div {
      margin-bottom: 0;
    }
  }
`
