import styled from '@emotion/styled';
import { ModalContainer } from '../Modal/styles'

export const ModalContent = styled(ModalContainer)`
  background-color: #fff; 
  position: relative;
`

export const ModalFileTitle = styled.p`
  font-size: 15px;
  line-height: 18px;
  font-weight: 400;
  color: #333333;
  width: 100%;
  padding-left: 24px;
`
export const ModalBottom = styled.div`
  display:flex;
  width: 100%;
  flex: 1 0 100%;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 16px 24px 24px;
  border-top: 1px solid #e9e9e9;
  margin-top: 28px;
`
