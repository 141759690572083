import MD5 from "crypto-js/md5";

export const getRandomIntInclusive = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const encodeBase64 = (str) => {
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
      function toSolidBytes(match, p1) {
          return String.fromCharCode('0x' + p1);
  }));
}

export const decodeBase64 = (str) => {
  return decodeURIComponent(atob(str).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
}

export function matchUri(str) {
  const uris = str.split(',');
  for (const uri of uris) {
    if (window.location.href.startsWith(uri)) {
      return uri;
    }
  }
}

export function getFilename(str) {
  if (str == null) return null;
  return str.split('/').pop();
}

export function guessMediaType(filename) {
  const ext = filename.split('.').pop().toLowerCase();

  switch (ext) {
    case 'mp4':
    case 'mpeg':
    case 'webm':
      return 'video';
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
      return 'image';
    case 'mp3':
      return 'audio';
    default:
      return null;
  }
}

export const getVideoThumbFileKey = (item) => {
  if (!item) return "None";
  const lastDot2 = item.lastIndexOf(".");
  return `${item.substring(0, lastDot2)}-video-thumbnail.jpg`;
};

export const getDate = (item) => {
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const date = new Date(item);
  const minutes = date.getUTCMinutes();
  return {
    year: date.getFullYear(),
    month: monthNames[date.getMonth()],
    day: date.getDate(),
    hour: date.getUTCHours() - (date.getTimezoneOffset() / 60),
    minutes: minutes < 10 ? `0${minutes}` : minutes,
    offset: date.getTimezoneOffset()
  }
} 

export const getFileByKey = (key, isPrivate, fileList) => fileList.find((item) => key === item.key && !isPrivate === !item.private);

export const singleData = (segments, text, settingsVariants, music) => {
  return {
    segments,
    textParams: {
      text,
      fontFamily: settingsVariants.font.fontFamily,
      fontSize: parseInt(settingsVariants.font.fontSize),
      fontColor: `#${settingsVariants.font.fontColor}`,
    },
    overlayParams: {
      bgColor: settingsVariants.overlayColor,
      valign: settingsVariants.position,
      topPadding: 10,
      bottomPadding: 10,
      leftPadding: 10,
      rightPadding: 10,
      fadeIn: settingsVariants.fadeIn * 1000,
      fadeOut: settingsVariants.fadeOut * 1000,
    },
    videoParams: {
      proportion: settingsVariants.proportions.valueName,
      width: settingsVariants.proportions.resolution.width,
      height: settingsVariants.proportions.resolution.height,
    },
    music,
  }
};


// Update media if older than 1 hours;
export const shouldRefreshMedia = (dttm) => ((Date.now() - dttm) / 1000) > 3600;

export const reloadMedia = (state, dispatch) => {
  if(shouldRefreshMedia(state.mediaListLoaded)) {
    dispatch({ type: 'updateMedia'})
  }
}

export const calculateJobRef = (job) => {
  const data = JSON.stringify(job).replace(/\?(.+?)"/g, '"');
  console.log ("[calculateJobRef]", data);
  return MD5(data).toString();
}

export const apiFetch = async (path, payload, requestHeaders = {}, method = "POST", getJson = true) => {
  const response = await fetch(path, {
    method: method,
    headers: { 
      "Content-Type": "application/json", 
      ...requestHeaders
    },
    body: payload ? JSON.stringify(payload) : undefined
  });
  const { status: code } = response;
  const json = getJson ? await response.json() : await response.text();
  console.log(`[ApiClient] fetch ${method} ${path} response`, { code }, json ? JSON.stringify(json) : null, { response });

  if (!response.ok) {
    // eslint-disable-next-line
    throw { code, ...json };
  }
  return json.data || json;
};

export const getLastIndex = (item, char) => {
  return item.lastIndexOf(char);
}
