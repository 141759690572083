import Amplify, { PubSub } from 'aws-amplify';
import { AWSIoTProvider } from '@aws-amplify/pubsub';
import { useEffect, useState } from 'react';

Amplify.addPluggable(new AWSIoTProvider({
    aws_pubsub_region: 'eu-west-1',
    aws_pubsub_endpoint: 'wss://a22rwl3wyvr4wh-ats.iot.eu-west-1.amazonaws.com/mqtt',
}));

export const useJobPubSub = (id) => {
    const [status, setStatus] = useState(null)
    useEffect(() => {
        if (!id) {
            setStatus(null);
            return;
        }
        console.log("[PUBSUB] subscribe" , id); 
       
        const sub = PubSub.subscribe(`job/${id}`).subscribe({
            next: ({value}) => {
                console.log('Message received', value);
                setStatus(value);
            },
            error: error => console.error(error, id),
            close: () => console.log('Done', id),
        });

        return () => {
            console.log("[PUBSUB] unsubscribe" , id); 
            sub.unsubscribe();
        }
    }, [id])
    return status;
}

export default useJobPubSub;