import React, {useContext} from "react";
import {DrawerContainer, CreateNewBtn} from './styles'
import {store } from '../../store'
import DrawerItem from "../DrawerItem";
import MenuProjectName from "../MenuProjectName";
import ModalComponent from "../Modal/index";
import CreateProject from '../CreateProject';

const RightDrawer = () => {
  const { dispatch, state, config } = useContext(store);
  const {modalCreateProject} = state;
  const projectsList = config.projects;

  const handleModalOpen = (isOpen) => {
    dispatch({type: 'openModalCreateProject', item: isOpen})
  }

  const handleToggleDrawer = (isOpen) => {
    dispatch({ type: 'setDrawerOpen', item: isOpen})
  }

  const handleNewProjectModal = () => { 
    handleModalOpen(true)
    handleToggleDrawer(false)
  }

  return (
    <>
    <DrawerContainer
      anchor={"left"}
      open={state.drawerOpen}
      onClose={() => handleToggleDrawer(false)}
      className={"drawer"}
    >
      <MenuProjectName className={"menu--relative"}/>
      { projectsList ? projectsList.map((item, index) => {
        return <DrawerItem key={index} item={item} />
      }) : null}
    <CreateNewBtn onClick={() =>  handleNewProjectModal()}>
      <p>Create a new project</p>
    </CreateNewBtn>
    </DrawerContainer>
      <ModalComponent
        open={modalCreateProject}
        close={() =>  handleModalOpen(false)}
      >
        <CreateProject click={() => handleModalOpen(false)} />
      </ModalComponent>
    </>
  );
};

export default RightDrawer;
