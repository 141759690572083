import styled from '@emotion/styled';

export const TitleContainer = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  flex-flow: row wrap;
  &.error {
    p {
      color: #DD0000;
    }
  }
`;

export const TitleText = styled.p`
  display: flex;
  color: ${props => props.color};
  font-family: ${props => props.fontFamily ? props.fontFamily : 'HalisRegular, sans-serif'};
  font-weight: 500;
  text-align: center;
  // @media screen and (max-width: 768px) {
    //   font-size: ${props => props.type === "Big" ? '42px' : props.type === 'Middle' ? '26px' : props.type === 'Small' ? '24px' : '28px'};
    //   line-height: ${props => props.type === "Big" ? '42px' : props.type === 'Middle' ? '30px' : props.type === 'Small' ? '32px' : '120%'};
  // }
  // @media screen and (max-width: 520px) {
    //   font-size: ${props => props.type === "Big" ? '40px' : props.type === 'Middle' ? '28px' : props.type === 'Small' ? '24px' : '28px'};
    //   line-height: ${props => props.type === "Big" ? '42px' : props.type === 'Middle' ? '32px' : props.type === 'Small' ? '32px' : '120%'};
  // }

  // @media screen and (min-width: 769px) {
    //   font-size: ${props => props.type === "Big" ? '64px' : props.type === 'Middle' ? '36px' : props.type === 'Small' ? '30px' : props.fontSize ? `${props.fontSize}px` : '28px'};
    //   line-height: ${props => props.type === "Big" ? '64px' : props.type === 'Middle' ? '40px' : props.type === 'Small' ? '42px' : '120%'};
  // }

  font-size: ${props => props.type === "Big" ? '64px' : props.type === 'Middle' ? '36px' : props.type === 'Small' ? '30px' : props.fontSize ? `${props.fontSize}px` : '28px'};
  line-height: ${props => props.type === "Big" ? '64px' : props.type === 'Middle' ? '40px' : props.type === 'Small' ? '42px' : '120%'};
`;
