import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

const SelectInput = (
  {
    id,
    className,
    click,
    values,
    defaultValue = 0,
    selectedValue = 0,
    isNone = false,
    isChoose = false,
    isError = false,
  }
) => {
  return (
    <Select
      disableUnderline={true}
      id={id}
      onChange={(e) => click(e)}
      className={`form-input ${className} ${isError ? 'select-input--error' : ''}`}
      classes={{
        root: `select-input`,
      }}
      value={selectedValue ? selectedValue : defaultValue}
    >
      {isChoose ? <MenuItem value="0" disabled>
        Choose...
      </MenuItem> : null}
      {isNone ? (
        <MenuItem value="none">
          None
        </MenuItem>
      ) : null}

      {values.map((item) => {
        return (
          <MenuItem value={item.value} key={item.value}>
            {item.valueName}
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default SelectInput;
