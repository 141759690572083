import React, { useContext, useEffect, useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { MediaContainer, SectionTitle, SectionContainer } from './styles';
import { store } from '../../store'
import MediaCard from "../../Components/MediaCard";
import Button from '@material-ui/core/Button';
import UploadForm from "../../Components/UploadForm"
import ModalComponent from "../../Components/Modal/index";
import BottomBar from '../../Components/BottomBar';
import { reloadMedia } from '../../helpers';

const Section = ({ title, items }) => items.length > 0 ?
  <SectionContainer>
    <SectionTitle>{title}</SectionTitle>
    <ResponsiveMasonry columnsCountBreakPoints={{ 520: 1, 750: 3, 900: 4 }}>
      <Masonry>
        {items.map((item, index) => <MediaCard item={item} key={index} />)}
      </Masonry>
    </ResponsiveMasonry>
  </SectionContainer>
  : null;

const Media = React.memo(() => {
  const { state, dispatch } = useContext(store);
  const {mediaList=[], modalOpen } = state;

  const [sharedFiles, setSharedFiles] = useState([]);
  const [privateFiles, setPrivateFiles] = useState([]);
  
//  useEffect(() => reloadMedia(state, dispatch), [])

  useEffect(() => {
    setSharedFiles(mediaList.filter( item => !item.key.includes('video-thumbnail') && !item.private ));
    setPrivateFiles(mediaList.filter( item => !item.key.includes('video-thumbnail') && item.private ));
  }, [mediaList])

  const setModal = (isOpen) => () => dispatch({ type: "setModalOpen", isOpen })

  return (
    <MediaContainer>
      <Section title={"My uploads"} items={privateFiles} />
      <Section title={"Shared"} items={sharedFiles} />
      <BottomBar>
        <Button className={"btn btn__upload"} onClick={setModal(true)}>
          Upload files
        </Button>
      </BottomBar>
      <ModalComponent open={modalOpen} close={setModal(false)} >
        <UploadForm />
      </ModalComponent>
    </MediaContainer>
  );
});

export default Media;
