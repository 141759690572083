import { Auth, Hub } from "aws-amplify";
import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import "./App.css";
import { AppContainer, AppContent, CreateBtnContainer } from "./App.styles";
import RightDrawer from "./Components/Drawer";
import MenuBar from "./Components/Menu";

import BeeRollForm from "./pages/editor";
import LoginScreen from './pages/LoginScreen';
import Media from './pages/media/index';
import RenderQueue from './pages/renderqueue';
import { store } from './store';
import { CreateNewBtn } from './Components/Drawer/styles'
import awsConfig from './aws-exports';

import { attachPolicy } from './particles/api';
import { useMedia } from './particles/media';
import { useIdleTimer } from 'react-idle-timer'
import LogRocket from 'logrocket';

const App = () => {
  const { dispatch, config } = useContext(store);
  const [user, setUser] = useState(null);
  const [userIdle, setUserIdle] = useState(false);

  const [initialized, setInitialized] = useState(false);
  useMedia(!!user, awsConfig.aws_user_files_s3_bucket_region, awsConfig.aws_user_files_s3_bucket);
  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          // case 'cognitoHostedUI':
          getUser().then(async userData => {
            console.log('userData', userData);
            setUser(userData);
            if (userData) {
              const userId = JSON.parse(userData.attributes.identities)[0].userId || null;
              config.setCurrentUser(userId);
              dispatch({ type: 'updateMedia' })
              await attachPolicy();
            }
          });
          break;
        case 'signOut':
        case 'oAuthSignOut':
          setUser(null);
          break;
        case 'signIn_failure':
          // case 'cognitoHostedUI_failure':
          setUser(null);
          console.log('Sign in failure', data);
          break;
        default:
          console.log('auth: unexpected event', event);
      }
    });

    getUser().then(async userData => {
      console.log('userData', userData);
      setUser(userData);
      if (userData) {
        const userId = JSON.parse(userData.attributes.identities)[0].userId;
        dispatch({ type: 'updateMedia' })
        config.setCurrentUser(userId);
        await attachPolicy();
      }
      setInitialized(true);
    });
  }, []);

  useEffect(() => {
    if (userIdle) return;
    const refteshSesion = async () => {
      console.log('try to refteshSesion');
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const currentSession = await Auth.currentSession();
        console.log('refteshSesion ....');
        cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
          console.log('session', err, session);
        //   const { idToken, refreshToken, accessToken } = session;
          // do whatever you want to do now :)
          dispatch({ type: "updateMedia" });
        });
      } catch (e) {
        console.log('Unable to refresh Token', e);
      }
    }
    refteshSesion();
    console.log("schedule refresh session timer ")
    const timer = setInterval(refteshSesion, 1000 * 60 * 10)
    return () => {
      console.log("cleare refresh session timer ")
      clearInterval(timer);
    }
  }, [userIdle])

  useIdleTimer({
    timeout: 1000 * 60 * 15,
    debounce: 500,
    onIdle: () => {
      console.log('user is idle');
      setUserIdle(true);
    },
    //  onAction: (e) => console.log('user did something', e),
    onActive: () => {
      setUserIdle(false);
      console.log('user is active')
      
    }
  })

  useEffect(() => {
    if (user) {
      dispatch({ type: "loadMediaList" });
    }
  }, [dispatch, user]);

  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then(userData => {
        LogRocket.identify(userData.username, {
          name: userData.attributes.name,
          email: userData.attributes.email,
        });
        return userData;
      })
      .catch(() => console.log('Not signed in'));
  }
  return (
    <AppContainer>
      {user ? (
        <Router>
          <AppContent>
            <MenuBar />
            <RightDrawer />
            {config.currentProject.length === 0 ?
              <CreateBtnContainer>
                <CreateNewBtn onClick={() => dispatch({ type: 'openModalCreateProject', item: true })}>
                  <p>Create a new project</p>
                </CreateNewBtn>
              </CreateBtnContainer>
              :
              <Switch>
                <Route path="/media">
                  <Media />
                </Route>
                <Route path="/editor">
                  <BeeRollForm />
                </Route>
                <Route path="/renderqueue">
                  <RenderQueue />
                </Route>
                <Route exact path="/">
                  <Redirect to="/renderqueue" />
                </Route>
              </Switch>
            }
          </AppContent>
        </Router>
      ) : initialized && (
        <LoginScreen
          googleLogin={() => Auth.federatedSignIn({ provider: 'Google' })}
          facebookLogin={() => Auth.federatedSignIn({ provider: 'Facebook' })}
        />
      )}
    </AppContainer>
  );
}

export default App;
