import React, { useContext, useEffect, useReducer, useRef, useState } from "react";
import { ContentContainer, InputFile, VideoContent } from "./styles";
import { ModalContainer } from "../Modal/styles";
import Title from "../Title/index";
import Button from "../Button";
import { CloseButton } from "../../pages/editor/styles";
import { store } from "../../store";
import { Storage } from 'aws-amplify';
import { CircularProgress, Switch, FormControlLabel } from '@material-ui/core';
import { getVideoThumbFileKey, guessMediaType } from '../../helpers'
import ModalBottom from '../ModalBottom'

function dataURLtoFile(dataurl, filename) {

  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

const initialState = {
  uploadItems: [],
}

function reducer(state, action) {
  const newUploadItems = [...state.uploadItems];

  switch (action.type) {
    case 'progress':
      newUploadItems[action.index] = { ...newUploadItems[action.index], progress: action.progress };
      break;
    case 'setUploaded':
      newUploadItems[action.index] = { ...newUploadItems[action.index], uploaded: action.uploaded };
      break;
    case 'changeFile':
      const files = action.e.target.files;
      for (let i = 0; i < files.length; i++) {
        const fileData = {
          file: files[i] || null,
          uploaded: false,
          progress: 0,
          index: newUploadItems.length + 1,
          thumb: false,
        }
        newUploadItems.push(fileData)
      }
      break;
    case 'changeThumbnail':
      const thumb = action.thumNailFile;
      const video = newUploadItems.find((item) => item.file.name === action.thumbFor);
      newUploadItems.push({ file: thumb, uploaded: false, progress: 0, thumb: true, thumbFor: action.thumbFor, isShared: video && video.isShared })
      break;
    case 'changeShared':
      const isShared = action.isShared || false;
      newUploadItems[action.index].isShared = isShared;
      if (newUploadItems[action.index].file.type.includes('video') && newUploadItems[action.index + 1]) {
        const currentName = newUploadItems[action.index].file.name;
        newUploadItems.find((item) => {
          if (item.thumbFor === currentName) {
            item.isShared = isShared;
          }
        })
      }
      break;
    case 'setDuration':
        const index = newUploadItems.findIndex((item) => item.file.name === action.fileName)
        newUploadItems[index] = { ...newUploadItems[index], duration: action.duration };
        break;
    case 'removeItem':
      const itemIndex = action.index;
      const currentName = newUploadItems[action.index].file.name;
      const thumbIndex = newUploadItems.findIndex((item) => item.thumbFor === currentName)
      newUploadItems.splice(thumbIndex, 1);
      newUploadItems.splice(itemIndex, 1);

      break;
    case 'clear':
      return { uploadItems: [] };
    default:
      throw new Error();
  }

  return { uploadItems: newUploadItems };
}

function UploadItem({ item, index, handleSharedChange, removeItem, disabled }) {

  const [isShared, setShared] = useState(false);
  useEffect(() => { handleSharedChange(isShared) }, [isShared]);

  return (
    <ContentContainer className="upload-item" key={index}>
      <p className={'upload-item__name'}>
        {item.file ? item.file.name : null}
        {item.progress > 0 && isShared ? <span className={'upload-item__type'}>- Shared</span> : null}
      </p>

      {item.progress === 0 ?
        <FormControlLabel
          control={
            <Switch
              checked={isShared}
              onChange={(e) => setShared(!isShared)}
              name="shared"
              color="primary"
            />
          }
          label="Shared"
          disabled={disabled}
        />
        :
        <CircularProgress variant="determinate" value={item.progress} />
      }
      <CloseButton
        onClick={() => !disabled && removeItem(index)}
        className={`upload-item__remove ${disabled ? 'disabled' : ''}`}
        disabled={disabled}
      />
    </ContentContainer>
  );
}

const UploadForm = React.forwardRef((props, ref) => {
  const { dispatch, config } = useContext(store);
  const formRef = useRef(null);
  const [uploading, setUploading] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [isUploadError, setIsUploadError] = useState(false);
  const [state, uploadDispatch] = useReducer(reducer, initialState);
  const [uploadCount, setUploadCount] = useState(0);
  
  useEffect(() => {
    uploadDispatch({ type: 'clear' });
  }, []);

  useEffect(() => {
    let counter = 0;
    state.uploadItems.map((item) => {
      if (item.thumb === false) {
        counter = counter + 1;
      }
      return true;
    })

    setUploadCount(counter)
  }, [state.uploadItems])

  const handleSubmit = () => {
    uploaded
      ? dispatch({ type: "setModalOpen", isOpen: false })
      : handleUpload()
  }

  const handleUpload = async () => {
    setUploading(true);
    setIsUploadError(false);

    try {
      const media = [];
      await Promise.all(
        state.uploadItems.map((item, index) => {
          return Storage.put(item.file.name, item.file, {
            contentType: item.file.type,
            level: item.isShared ? 'public' : 'private',
            progressCallback(pe) {
              const progress = Math.ceil(pe.loaded / pe.total * 100);
              uploadDispatch({ type: 'progress', index, progress });
            },
            metadata: item.duration ? { duration: `${item.duration}` } : undefined
          })
            .then(async (result) => {
              console.log('put result', result, item);
              uploadDispatch({ type: 'setUploaded', index, uploaded: true });
              
              const { key } = result;
              media.push({
                type: guessMediaType(key),
                key,
                size: item.file.size,
                date: Date.now(),
                private: !item.isShared,
                duration: item.duration 
              })
            })
          // .catch((err) => {
          //   console.log(err);
          //   return { ...item, error: true };
          // });
        })
      );
      dispatch({ type: "setMedia", item: media });

      setUploaded(true);
    }
    catch (err) {
      console.error(err);
      setIsUploadError(true);
    }
    setUploading(false);
    //dispatch({ type: "loadMediaList" });
  };

  const filledItems = () => state.uploadItems.filter(item => item.file != null && item.thumb === false);

  function capture(video) {
    const videoWidth = video.getBoundingClientRect().width;
    const videoHeight = video.getBoundingClientRect().height;
    const ratio = videoHeight / videoWidth;
    const canvas = document.createElement('canvas');
    canvas.width = 512;
    canvas.height = 512 * ratio;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(video, 0, 0, 512, 512 * ratio);
    return canvas.toDataURL('image/jpeg');
  }

  const grabThumbnail = (item) => {
    const files = item.target.files;
    let thumbsCounter = 0;
    for (let file of files) {
      if (file.type.includes('video')) {
        thumbsCounter = thumbsCounter + 1;
        const videoItem = document.createElement('video');
        const fileObject = URL.createObjectURL(file);
        videoItem.setAttribute('src', fileObject + '#t=0.1')
        videoItem.classList.add(`video-${thumbsCounter}`);
        videoItem.setAttribute('controls', true)
        videoItem.setAttribute('muted', true);
        document.body.appendChild(videoItem)
        const videoItemClass = document.querySelector(`.video-${thumbsCounter}`)
        videoItem.onloadeddata = () => {
          const thumnail = capture(videoItemClass, 1);
          const thumNailFile = dataURLtoFile(thumnail, getVideoThumbFileKey(file.name));
          uploadDispatch({ type: 'changeThumbnail', thumNailFile, thumbFor: file.name })
          videoItem.remove();
        }
        videoItem.onloadedmetadata = () => {
          uploadDispatch({ type: 'setDuration', fileName: file.name, duration : Math.floor(videoItem.duration * 1000) })
        }
      }
    }
  }

  return (
    <ModalContainer ref={ref} tabIndex={-1} onKeyDown={(e) => e.key === "Enter" && handleSubmit()}>
      <form ref={formRef} className={"upload-form"}>
        <ContentContainer className="title__container">
          <Title
            className={`title ${isUploadError ? 'error' : ''}`}
            text={uploaded
              ? "Files uploaded successfully!"
              : (uploading
                ? "Uploading..."
                : (isUploadError
                  ? "There was an error"
                  : "Upload files"))
            }
          />
          <CloseButton
            onClick={() => dispatch({ type: "setModalOpen", isOpen: false })}
            className={uploading ? 'disabled' : ''}
          />
        </ContentContainer>
        {state.uploadItems.map((item, index) => {
          if (item.thumb === false) {
            return (
              <UploadItem
                key={index}
                index={index}
                item={item}
                handleSharedChange={(isShared) => uploadDispatch({ type: 'changeShared', index, isShared })}
                removeItem={() => uploadDispatch({ type: 'removeItem', index })}
                disabled={uploading || uploaded}
              />
            )
          } else {
            return false;
          }
        }
        )}
        <ModalBottom>
          {!uploading && !uploaded && uploadCount < 20 && (
            <InputFile>
              <input
                id={`fileUpload`}
                type="file"
                onChange={(e) => { uploadDispatch({ type: 'changeFile', e}); grabThumbnail(e) }}
                accept="video/mp4,image/png,image/gif,image/jpeg,audio/mpeg"
                multiple
              /* readOnly={disabled} */
              />
              <label htmlFor={`fileUpload`}>
                <span>Add file</span>
              </label>
            </InputFile>
          )}
          <Button
            typeButton="button"
            className="btn__big btn__upload-now"
            click={handleSubmit}
            disabled={state.uploadItems.length < 1 || filledItems().length !== uploadCount || uploading}
          >
            {uploaded
              ? "Close"
              : (uploading
                ? "Uploading..."
                : `Upload now (${uploadCount})`)}
          </Button>
        </ModalBottom>
      </form>
    </ModalContainer>
  );
});

export default UploadForm;
