import { API, Auth } from 'aws-amplify';
import { IoTClient, ListAttachedPoliciesCommand } from '@aws-sdk/client-iot';
import awsConfig from '../aws-exports';

export const attachPolicy = async () => {
  const credentials = await Auth.currentCredentials();

  const iotclient = new IoTClient({
    region: awsConfig.aws_project_region,
    credentials: Auth.essentialCredentials(credentials),
  });

  const policyName = 'myIoTPolicy';
  const target = credentials.identityId;

  const command = new ListAttachedPoliciesCommand({ target });
  const { policies } = await iotclient.send(command);
  console.log('[API] policies', policies);

  if (!policies.find(policy => policy.policyName === policyName)) {
    console.log('[API] POST attachPolicy');
    await API.post('api', '/policy/attach');
  }
};
