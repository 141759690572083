import React, { useContext, useEffect, useState } from "react";
import {
  CloseButton,
  ContentContainer,
  OptionContainer,
  SingleVariant,
  TextSettingsItem,
  TextSettingsLeft,
  TextSettingsRight,
  VariantTitle,
} from "../../styles";
import { FullContent, Half20, Half40, Half60, Half80, Subtitle } from './styles';
import InputAdornment from "@material-ui/core/InputAdornment";
import { ModalContainer } from "../../../../Components/Modal/styles";
import { store } from "../../../../store";
import TextField from "@material-ui/core/TextField";
import SelectInput from "../../../../Components/Select";
import Slider from '@material-ui/core/Slider';

const proportionsDefault = [
  {
    value: "0",
    valueName: "16:9",
    resolution: { width: "1920", height: "1080" },
  },
  {
    value: "1",
    valueName: "9:16",
    resolution: { width: "1080", height: "1920" },
  },
];

const Settings = React.forwardRef((props, ref) => {
  const globaStore = useContext(store);

  console.log(globaStore);
  const {
    dispatch,
    config: {settingsVariants, setSettingsVariants}
  } = globaStore;
  const { loadingState } = props;
  const [fontColor, setFontColor] = useState(settingsVariants.font.fontColor);
  const [fontSize, setFontSize] = useState(settingsVariants.font.fontSize);
  const [fontFamilyList, setFontFamilyList] = useState([{ value: 0, valueName: 'Roboto' }])
  const [fontFamily, setFontFamily] = useState("1");
  const [overlayBgColor, setOverlayBgColor] = useState(
    settingsVariants.overlayColor
  );
  // eslint-disable-next-line
  const [overlayPosition, setOverlayPosition] = useState(
    settingsVariants.position
  );
  const [overlayFadeIn, setOverlayFadeIn] = useState(
    settingsVariants.fadeIn
  );
  const [overlayFadeOut, setOverlayFadeOut] = useState(
    settingsVariants.fadeOut
  );
  const [proportions, setProportions] = useState(
    (proportionsDefault.find(({ value }) => value === settingsVariants.proportions.value) || {}).value || 0
  );
  const [resolution, setResolution] = useState({
    width: proportionsDefault[0].resolution.width,
    height: proportionsDefault[0].resolution.height,
  });

  useEffect(() => {
    fetch(
      'https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyBjaNiHrtHzb97Hz-BaV2KcA5w7dJKHTio')
    .then(response => response.json())
    .then(data => {
      let fontList = [];

      data.items.map((item, index) => {
        fontList.push({ value: index, valueName: item.family });
        return true;
      })
      setFontFamilyList(fontList)
    })
  }, [])

  useEffect(() => {
    if(settingsVariants.length !== 0) {
      setFontFamily(fontFamilyList.findIndex(({ valueName }) => valueName === settingsVariants.font.fontFamily))
    }
  }, [fontFamilyList, settingsVariants])

  const handleFontFamilyChange = (e) => {
    setFontFamily(e.target.value)
    const newSettings = {...settingsVariants};
    newSettings.font.fontFamily = fontFamilyList[e.target.value].valueName;
    setSettingsVariants(newSettings);
  }
  const handleFontColorChange = (e) => {
    const colorValue = e.target.value;
    const regHex = /^([0-9A-F]{3}){1,2}$/i;
    if (regHex.test(colorValue.toUpperCase())) {
      e.target.parentNode.parentNode.classList.remove("invalid");
    } else {
      e.target.parentNode.parentNode.classList.add("invalid");
    }
    setFontColor(colorValue);
    const newSettings = {...settingsVariants};
    newSettings.font.fontColor = colorValue;
    setSettingsVariants(newSettings);
  };

  const handleFontSizeChange = (e) => {
    const regNumber = /^\d+$/;
    if (regNumber.test(e.target.value) || e.target.value === "") {
      setFontSize(e.target.value);
      const newSettings = {...settingsVariants};
      newSettings.font.fontSize = e.target.value;
      setSettingsVariants(newSettings);
    }
  };

  const handleOverlayColorChange = (e) => {
    setOverlayBgColor(e.target.value);
    const newSettings = settingsVariants;
    newSettings.overlayColor = e.target.value;
    setSettingsVariants(newSettings);
  };

  const handlePositionChange = (e, value) => {
    const newSettings = settingsVariants;
    newSettings.position = value;
    setSettingsVariants(newSettings);
  };

  const handleStartChange = (e) => {
    const regNumber = /^\d+$/;
    if ((regNumber.test(e.target.value) && e.target.value < 1001) || e.target.value === "") {
      setOverlayFadeIn(e.target.value);
      const newSettings = settingsVariants;
      newSettings.fadeIn = e.target.value;
      setSettingsVariants(newSettings);
    } else if (e.target.value > 1000) {
      e.target.value = 1000;
    }
  };

  const handleFadeOutChange = (e) => {
    const regNumber = /^\d+$/;
    if ((regNumber.test(e.target.value) && e.target.value < 1001) || e.target.value === "") {
      setOverlayFadeOut(e.target.value);
      const newSettings = settingsVariants;
      newSettings.fadeOut = e.target.value;
      setSettingsVariants(newSettings);
    } else if (e.target.value > 1000) {
      e.target.value = 1000;
    }
  };

  const handleProportionsChange = (e) => {
    setProportions(e.target.value);
    const proportion = proportionsDefault[e.target.value];
    setResolution({
      width: proportion.resolution.width,
      height: proportion.resolution.height,
    });
    const newSettings = settingsVariants;
    newSettings.proportions.value = proportion.value;
    newSettings.proportions.valueName =  proportion.valueName;
    newSettings.proportions.resolution = { width: proportion.resolution.width, height: proportion.resolution.height };
    setSettingsVariants(newSettings);
  };

  return (
    <ModalContainer ref={ref} tabIndex={-1} width={800}>
      <OptionContainer className={"text-settings"}>
        <ContentContainer className="text-settings__header">
          <VariantTitle>Settings</VariantTitle>
          <CloseButton
            onClick={() =>
              dispatch({ type: "setTextModalOpen", isOpen: false })
            }
            className="text-settings__close"
          />
        </ContentContainer>
        <div name="text-settings">
          <SingleVariant
            loadingState={loadingState}
            className={"text-settings__content"}
          >
            <Half40>
              <Subtitle>Text</Subtitle>
              <TextSettingsItem>
                <TextSettingsLeft>Font family:</TextSettingsLeft>
                <TextSettingsRight>
                  <SelectInput
                    id="fontFamily"
                    click={(e) => handleFontFamilyChange(e)}
                    values={fontFamilyList}
                    defaultValue={fontFamily}
                    className={"select-input"}
                  />
                </TextSettingsRight>
              </TextSettingsItem>
              <TextSettingsItem>
                <TextSettingsLeft>Font color:</TextSettingsLeft>
                <TextSettingsRight>
                  <TextField
                    disableunderline={"true"}
                    id="fontColor"
                    classes={{ root: "form-input" }}
                    onChange={(e) => handleFontColorChange(e)}
                    value={fontColor}
                    InputProps={{
                      inputProps: { maxLength: 6 },
                      startAdornment: (
                        <InputAdornment
                          classes={{ root: "form-input--adornment" }}
                          position="start"
                        >
                          #
                        </InputAdornment>
                      ),
                    }}
                  />
                </TextSettingsRight>
              </TextSettingsItem>
              <TextSettingsItem>
                <TextSettingsLeft>Font size:</TextSettingsLeft>
                <TextSettingsRight>
                  <TextField
                    disableunderline={"true"}
                    id="fontSize"
                    classes={{ root: "form-input" }}
                    onChange={(e) => handleFontSizeChange(e)}
                    value={fontSize}
                    inputProps={{
                      maxLength: 3,
                    }}
                  />
                </TextSettingsRight>
              </TextSettingsItem>
            </Half40>
            <Half60>
              <Subtitle>Overlay</Subtitle>
              <FullContent className={"overlay-content"}>
                <Half80>
                  <TextSettingsItem>
                    <TextSettingsLeft>Overlay bg color (RGBA):</TextSettingsLeft>

                    <TextSettingsRight>
                      <TextField
                        disableunderline={"true"}
                        id="overlayColor"
                        classes={{ root: "form-input" }}
                        onChange={(e) => handleOverlayColorChange(e)}
                        value={overlayBgColor}
                      />
                    </TextSettingsRight>
                  </TextSettingsItem>
                  <TextSettingsItem>
                    <TextSettingsLeft>Starts at:</TextSettingsLeft>
                    <TextSettingsRight>
                      <TextField
                        disableunderline={"true"}
                        id="overlayFadeIn"
                        classes={{ root: "form-input additional-info" }}
                        onChange={(e) => handleStartChange(e)}
                        value={overlayFadeIn}
                        inputProps={{
                          maxLength: 4,
                          min: 0,
                          max: 1000,
                        }}
                        helperText="seconds (integer)"
                      />
                    </TextSettingsRight>
                  </TextSettingsItem>
                  <TextSettingsItem>
                    <TextSettingsLeft>Fades out after:</TextSettingsLeft>
                    <TextSettingsRight>
                      <TextField
                        disableunderline={"true"}
                        id="overlayFadeOut"
                        classes={{ root: "form-input additional-info" }}
                        onChange={(e) => handleFadeOutChange(e)}
                        value={overlayFadeOut}
                        inputProps={{
                          maxLength: 4,
                          min: 0,
                          max: 1000,
                        }}
                        helperText="seconds (integer)"
                      />
                    </TextSettingsRight>
                  </TextSettingsItem>
                </Half80>
                <Half20 className={"slider-container"}>
                  <Slider
                    orientation="vertical"
                    defaultValue={overlayPosition}
                    aria-labelledby="vertical-slider"
                    className={"slider"}
                    onChangeCommitted={handlePositionChange}
                  />
                  <TextSettingsLeft className={"slider-text"}>Position</TextSettingsLeft>
                </Half20>
              </FullContent>
            </Half60>
            <FullContent>
              <Subtitle>Video</Subtitle>
              <TextSettingsItem>
                <TextSettingsLeft className="input-label">Proportions:</TextSettingsLeft>
                <TextSettingsRight>
                  <SelectInput
                    id="proportions"
                    click={(e) => handleProportionsChange(e)}
                    values={proportionsDefault}
                    defaultValue={proportions}
                    className={"select-input"}
                  />
                </TextSettingsRight>
              </TextSettingsItem>
              <TextSettingsItem>
                <TextSettingsLeft className="input-label">Resolution:</TextSettingsLeft>
                <TextSettingsRight>
                  <TextField
                    disableunderline={"true"}
                    id="resolution"
                    classes={{ root: "form-input" }}
                    value={`${resolution.width}x${resolution.height}`}
                    disabled={true}
                  />
                </TextSettingsRight>
              </TextSettingsItem>
            </FullContent>
          </SingleVariant>
        </div>
      </OptionContainer>
    </ModalContainer>
  );
});

export default Settings;
