import styled from '@emotion/styled';

export const CardTitle = styled.p`
  font-size: 13px;
  line-height: 18px;
  color: #686868;
  font-weight: 400;
  margin-top: 14px;
  margin-bottom: 16px;
`

export const RemoveItem = styled.p`
  font-size: 13px;
  line-height: 15px;
  font-weight: 500;
  color: #2F80ED;
  cursor: pointer;
`
