import React from 'react';
import { useMediaUrl } from '../../particles/media';
import { Audio } from './styles'

const AudioItem = ({ url }) => {
  return (
    <Audio controls preload="none">
      <source src={url} type="audio/mpeg" />
    </Audio>
  )
}

export const MediaAudioItem = ({ mediaKey, isPrivate }) => {
  const url = useMediaUrl(mediaKey, isPrivate);
  return <AudioItem url={url} />
}

export default MediaAudioItem;
