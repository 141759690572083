import { FacebookButton, GoogleButton, LoginButtons, LoginTitle, LoginWrapper } from './styles';
import PropTypes from 'prop-types';
import { FaFacebook, FaGoogle } from 'react-icons/fa';

const LoginScreen = (props) => {
  const { googleLogin, facebookLogin } = props;
  return (
    <LoginWrapper>
      <LoginTitle>Can Explode Platform</LoginTitle>
      <LoginButtons>
        <GoogleButton onClick={googleLogin} variant="contained" color="primary" startIcon={<FaGoogle color='white'/>}>
          Sign in with Google
        </GoogleButton>
        <FacebookButton onClick={facebookLogin} variant="contained" color="primary" startIcon={<FaFacebook color='white'/>}>
          Sign in with Facebook
        </FacebookButton>
      </LoginButtons>
    </LoginWrapper>
  )
}

LoginScreen.propTypes = {
  googleLogin: PropTypes.func,
  facebookLogin: PropTypes.func,
}

export default LoginScreen;
