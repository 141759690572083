import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { ModalContainer } from "../Modal/styles";
import ModalHeader from "../ModalHeader";
import { CreateProjectContainer, ModalContent } from "./styles";
import ModalBottom from "../ModalBottom";
import Button from "../Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { v4 as uuid_v4 } from "uuid";
import {store} from '../../store'

const useStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid #A7A7A7",
    borderRadius: "4px",
    overflow: "hidden",
    width: "100%",
    height: "44px",
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  input: {
    height: "44px",
    fontSize: "15px",
  },
}));

const CreateProject = React.forwardRef((props, ref) => {
  const {dispatch, config} = useContext(store);
  const classes = useStyles();
  const [textValue, setTextValue] = useState("");
  const history = useHistory();
  const { projects, setProjects} = config;

  const handleTextChange = (e) => {
    setTextValue(e.target.value);
  };

  const handleCreateProject = () => {
    const newProject = {name: textValue, id: uuid_v4(), editor: 'beeRoll'};
    setProjects([...projects, newProject])
    config.setCurrentProject(newProject.id)
    dispatch({type: 'setCurrentProject', item: newProject})
    history.push("/editor");
    props.click();
  };

  return (
    <ModalContainer ref={ref} tabIndex={-1}>
      <CreateProjectContainer onKeyDown={(e) => { if(e.key === "Enter" && textValue) {handleCreateProject()}}}>
        <ModalHeader
          title="Create a new project"
          click={props.click}
        ></ModalHeader>
        <ModalContent>
          <TextField
            placeholder="Add project name..."
            classes={{
              root: classes.root,
            }}
            onChange={(e) => handleTextChange(e)}
            required
            InputProps={{
              disableUnderline: true,
              classes: {
                root: classes.input,
              },
            }}
          />
        </ModalContent>
        <ModalBottom>
          <Button
            typeButton="buton"
            className="btn btn__big btn__upload-now"
            disabled={textValue === "" ? true : false}
            click={() => handleCreateProject()}
          >
            Create
          </Button>
        </ModalBottom>
      </CreateProjectContainer>
    </ModalContainer>
  );
});

export default CreateProject;
