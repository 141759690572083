import React, { useContext, useRef, useState,useEffect } from "react";
import { Video } from "./styles";
import { InView } from 'react-intersection-observer';
import { useMediaUrl } from "../../particles/media";
import { store } from "../../store";

export const VideoItem = ({ url, posterUrl, isLoaded = () => { } }) => {

  const { state: {playVideo}, dispatch } = useContext(store);
  const videoItem = useRef(null);
  const [isInView, setInView] = useState(false);
  const [src, setSrc] = useState(url);

  const handleHover = (isHover) => {
    if (isHover) {
      videoItem.current.setAttribute("controls", true);
      videoItem.current.setAttribute("preload", "auto");
    } else {
      videoItem.current.removeAttribute("controls");
    }
  };

  const isPlaying = () => {
    const video = videoItem.current;
    return !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);
  }

  useEffect(() => {
    if (src) return;
    setSrc(url);
  }, [src, url])


  useEffect(() => {
    if (playVideo && playVideo != url && isPlaying()) {
     setSrc(undefined);
     videoItem.current.setAttribute("preload", posterUrl ? "none" : "preload");
    }
  }, [playVideo, url])

  const handleCanPlay = () => isLoaded(true)

  return (
    <InView as="div" className={"inview-element"} onChange={setInView} skip={isInView}>
      <Video
        src={src}
        ref={videoItem}
        preload={posterUrl ? "none" : "preload"}
        poster={isInView && posterUrl ? posterUrl : ""}
        onMouseEnter={() => handleHover(true)}
        onMouseLeave={() => handleHover(false)}
        onCanPlay={() => handleCanPlay()}
        onPlay={()=> dispatch({ type: 'playVideo', url })}
        onEnded = {() => dispatch({ type: 'playVideo', url: undefined })}
      />
    </InView>
  );
};

export const MediaVideoItem = ({ mediaKey, posterKey, isPrivate, isLoaded = () => { } }) => {
  const url = useMediaUrl(mediaKey, isPrivate);
  const posterUrl = useMediaUrl(posterKey, isPrivate);
  return <VideoItem url={url} posterUrl={posterUrl} isLoaded={isLoaded} />
}

export default MediaVideoItem;