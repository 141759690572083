import styled from "@emotion/styled";
import Button from '@material-ui/core/Button';

export const LoginWrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 100%;
  flex-flow: row wrap;
  align-content: center;
`;

export const LoginTitle = styled.h1`
  font-size: 40px;
  line-height: 120%;
  font-weight: 900;
  width: 100%;
  text-align: center;
  margin-bottom: 40px;
  margin-top: 0;
`;

export const LoginButtons = styled.div`
  flex: 1 0 100%;
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const GoogleButton = styled(Button)`
  &.MuiButton-containedPrimary {
    color: white;
    background-color: #3f85f7;
  }
`;

export const FacebookButton = styled(Button)`
  &.MuiButton-containedPrimary {
    color: white;
    background-color: #3a599a;
  }
`;
