import styled from "@emotion/styled";

export const AppContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  min-height: 100%;
  position: relative;
  background-color: #E5E5E5;
`

export const MenuContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #fff;
  z-index: 10;
`

export const ProjectContainer = styled.div`
  display: ${props => props.active ? 'flex' : 'none'};
  width: 100%;
  flex: 1 0 100%;
  height: 100%;
  justify-content: center;
`

export const AppContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-flow: row wrap;
  width: 100%;
  flex: 1 0 100%;
  padding-top: 72px;
`;

export const CreateBtnContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`
