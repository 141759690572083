import styled from "@emotion/styled";

export const DrawerItemContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row wrap;
  border-radius: 4px;
  overflow: hidden;
  margin: 0 8px 10px;
  width: 272px;
  cursor: pointer;
  position: relative;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    .delete-project {
      opacity: 1;
    }
  }
  &.active {
    background-color: #333333;
    p {
      color: #fff;
    }
    .delete-project {
      &:after,
      &:before {
        background-color: #fff;
        opacity: 1;
      }
    }
  }
`;

export const DrawerItemContent = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row wrap;
  flex: 1 0 100%;
  padding: 8px 16px;
`

export const DrawerItemName = styled.p`
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  line-height: 100%;
  color: #333;
  margin: 0 0 0 18px;
  padding: 0;
  max-width: calc(100% - 62px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const DrawerItemDelete = styled.span`
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -10px;
  display: block;
  width: 20px;
  height: 20px;
  opacity: 0;
  transition: opacity .2s ease-in-out;
  &:after,
  &:before {
    content: "";
    width: 20px;
    height: 1px;
    background-color: #333;
    position: absolute;
    top: 50%;
    left: 0;
  }
  &:after {
    transform: rotate(45deg);
  }
  &:before {
    transform: rotate(-45deg);
  }
`;
