import React, { createContext, useEffect, useReducer } from "react";
import { Storage, Auth } from "aws-amplify";
import { useLocalStorage } from "./utils/useLocalStorage";
import { singleData, encodeBase64, calculateJobRef, apiFetch } from "./helpers";

export const settingsValues = {
  font: {
    fontFamily: 'Roboto',
    fontColor: "000000",
    fontSize: "138",
  },
  overlayColor: "255,255,255, 0.9",
  proportions: {
    value: "0",
    valueName: "16:9",
    resolution: { width: "1920", height: "1080" },
  }
  ,
  position: 50,
  fadeIn: 0,
  fadeOut: 0,
  anchor: [
    {
      value: 1,
      valueName: "Beginning",
    },
    {
      value: 2,
      valueName: "End",
    },
  ],
};

const initialState = {
  videoClips: [],
  audioClips: [],
  modalOpen: false,
  modalCreateProject: false,
  textModalOpen: false,
  drawerOpen: false,
  formSubmitted: false,
  currentProject: {},
  mediaList: [],
  mediaLoaded: false,
  jobs: [],
  mediaListLoaded: null,
  mediaUpdate: true,
};

const store = createContext(initialState);
const { Provider } = store;

const DATA_VERSION = '0010';
const getDVStorageName = (name) => `${DATA_VERSION}_${name}`;
export const getProjectStorageName = (currentUser, projectId, name) => getDVStorageName(`${currentUser}_${projectId}_${name}`)

const StateProvider = ({ env, children }) => {
  const newSettings = JSON.stringify(settingsValues);
  const [currentUser, setCurrentUser] = useLocalStorage(getDVStorageName("beeRollCurrentUser"), "")
  const [currentProject, setCurrentProject] = useLocalStorage(getDVStorageName(`${currentUser}-beeRollCurrent`), "");
  const [projects, setProjects] = useLocalStorage(getDVStorageName(`${currentUser}-beeRollProjects`), []);
  const [storedState, setStoreState] = useLocalStorage(getDVStorageName(`${currentUser}-beeRollForm`), initialState);
  const [textVariants, setTextVariants] = useLocalStorage(getProjectStorageName(currentUser, currentProject, "beeRollTextVariants"), []);
  const [videoVariants, setVideoVariants] = useLocalStorage(getProjectStorageName(currentUser, currentProject, "beeRollVideoVariants"), []);
  const [audioVariants, setAudioVariants] = useLocalStorage(getProjectStorageName(currentUser, currentProject, "beeRollAudioVariants"), []);
  const [settingsVariants, setSettingsVariants] = useLocalStorage(getProjectStorageName(currentUser, currentProject, "beeRollSettingsVariants"), JSON.parse(newSettings));
  const [renderQueue, setRenderQueue] = useLocalStorage(getProjectStorageName(currentUser, currentProject, "beeRollRenderQueue"), []);

  const getSignedUrl = async (key, isPrivate, expires = 86400) => await Storage.get(key, { level: isPrivate ? 'private' : 'public', expires });
  const getMediaUrl = async (key, isPrivate) => {
    if (!key) return undefined;
    return await getSignedUrl(key, isPrivate);
  }

  const getResolvedUrls = async (segmentsKeys, musicKey) => {

    console.log("getResolvedUrls", { segmentsKeys, musicKey });

    const music = musicKey ? await getMediaUrl(musicKey.key, musicKey.private) : musicKey;
    const segments = await Promise.all(segmentsKeys.map(async ({ baseVideo, ...itemSegment }) => {
      return {
        ...itemSegment,
        baseVideo: await getMediaUrl(baseVideo.key, baseVideo.private),
        overlayVideo: await Promise.all(itemSegment.overlayVideo.map(async (video) => await getMediaUrl(video.key, video.private)))
      }
    }));
    return {
      segments, music
    }
  }

  const buildJobRequest = ({ height, width, length, segments, text, music }) => {
    const singeData = singleData(segments, text, settingsVariants, music);
    const ref = calculateJobRef(singeData);
    console.log(ref);
    const data = encodeBase64(JSON.stringify(singeData));
    console.log(`code for param &data=${data}`);

    const template = `beeroll-demo-${env || 'dev'}`;

    console.log({ template });

    return {
      url: `https://${env === 'prod' ? 'sdp7puquz0' : 'piw40lzusi'}.execute-api.eu-west-1.amazonaws.com/${env || 'dev'}/job`,
      methdod: "POST",
      payload: {
        template,
        width,
        height,
        length,
        zoom: 1,
        data,
        ref
      }
    }
  }

  const config = {
    textVariants, setTextVariants,
    videoVariants, setVideoVariants,
    audioVariants, setAudioVariants,
    projects, setProjects,
    currentProject, setCurrentProject,
    settingsVariants, setSettingsVariants,
    renderQueue, setRenderQueue,
    currentUser, setCurrentUser,
    reset: () => {
      setTextVariants([]);
      setVideoVariants([]);
      setAudioVariants([]);
      setSettingsVariants(JSON.parse(newSettings));
    },
    resetConfig: () => {
      setCurrentUser(null);
    },
    getResolvedUrls,
    getSignedUrl,
    buildJobRequest,
    scheduledJobs: async (jobs) => {
      try {
        const newJobs = await Promise.all(
          jobs.map(async (job) => {
            const { request: { url, payload } } = job;
            const token = (await Auth.currentSession()).getIdToken().getJwtToken();
            return apiFetch(url, payload, { 'Authorization': token }).then(({ url: resultUrl, id }) => {
              return { ...job, resultUrl, id, url: `${url}/${id}`, date: Date.now() };
            });
          })
        );
        const newIds = newJobs.map(({ id }) => id);

        console.log({ newIds, renderQueue, filtered: renderQueue.filter((item) => !newIds.includes(item.id)) });

        const newQueue = [...renderQueue.filter((item) => !newIds.includes(item.id)), ...newJobs];
        newQueue.sort((a, b) => (parseFloat(b.date) > parseFloat(a.date)) ? 1 : -1);
        setRenderQueue(newQueue)
      }
      catch (err) {
        console.error(err);
      }
    },
    refreshJobFiles: async (job) => {
      const { text, music, segments, request: { payload: { width, height, length } } } = job;
      const dateNow = Date.now();
      const resolved = await getResolvedUrls(segments, music);
      return {
        ...job,
        date: dateNow,
        request: buildJobRequest({ ...resolved, text, height, width, length })
      }
    }
  };

  const [state, dispatch] = useReducer((state, action) => {
    // console.log('action', action.type);
    switch (action.type) {
      case "setFontFamily":
        return {
          ...state,
          textSettings: {
            ...state.textSettings,
            fontFamily: action.item,
          }
        }
      case "setFontColor":
        return {
          ...state,
          textSettings: {
            ...state.textSettings,
            fontColor: action.item,
          },
        };
      case "setFontSize":
        return {
          ...state,
          textSettings: {
            ...state.textSettings,
            fontSize: action.item,
          },
        };
      case "setOverlayColor":
        return {
          ...state,
          overlaySettings: {
            ...state.overlaySettings,
            overlayColor: action.item,
          },
        };
      case "setOverlayPosition":
        return {
          ...state,
          overlaySettings: {
            ...state.overlaySettings,
            overlayPosition: action.item,
          },
        };
      case "setOverlayFadeIn":
        return {
          ...state,
          overlaySettings: {
            ...state.overlaySettings,
            overlayFadeIn: action.item,
          },
        };
      case "setOverlayFadeOut":
        return {
          ...state,
          overlaySettings: {
            ...state.overlaySettings,
            overlayFadeOut: action.item,
          },
        };
      case "setVideoClips":
        return {
          ...state,
          videoClips: action.videoClips,
        };
      case "setAudioClips":
        return {
          ...state,
          audioClips: action.audioClips,
        };
      case "addVideoClip":
        return {
          ...state,
          videoClips: [...state.videoClips, action.videoClip],
        };
      case "addAudioClip":
        return {
          ...state,
          videoClips: [...state.audioClips, action.audioClip],
        };
      case "setModalOpen":
        return {
          ...state,
          modalOpen: action.isOpen,
        };
      case "setTextModalOpen":
        return {
          ...state,
          textModalOpen: action.isOpen,
        };
      case "setFormSubmitted":
        return {
          ...state,
          formSubmitted: action.item,
        };
      case "setProportion":
        return {
          ...state,
          proportion: {
            name: action.item.name,
            width: action.item.width,
            height: action.item.height,
          },
        };
      case 'setDrawerOpen':
        return {
          ...state,
          drawerOpen: action.item,
        }
      case 'loadMediaList':
        return {
          ...state,
          mediaLoaded: false,
          mediaUpdate: true,
        }
      case 'setMediaList':
        console.log('setMediaList', action.item);
        return {
          ...state,
          mediaList: action.item,
          mediaLoaded: true,
          mediaUpdate: false,
          mediaListLoaded: Date.now()
        }
      case 'updateMedia':
        return {
          ...state,
          mediaUpdate: true
        }
      case 'setMedia':
        const filtered = state.mediaList.filter((current) => !action.item.find(value => value.key === current.key && value.private === current.private));
        console.log('setMedia', action.item, filtered);
        return {
          ...state,
          mediaList: [...filtered, ...action.item].sort(function (a, b) {
            if (a.key < b.key) { return -1; }
            if (a.key > b.key) { return 1; }
            return 0;
          })
        }
      case 'removeMedia':
        return {
          ...state,
          mediaList: [...state.mediaList.filter(value => value.key !== action.item.key || value.private !== action.item.private)]
        }
      case 'setJobs':
        return {
          ...state,
          jobs: action.newJobs
        }
      case 'setCurrentProject':
        return {
          ...state,
          currentProject: action.item,
        }
      case 'openModalCreateProject':
        return {
          ...state,
          modalCreateProject: action.item,
        }
      case 'setInitialState':
        return {
          ...initialState,
        }
        case 'playVideo':
          return {
            ...state,
            playVideo: action.url
          }  
      default:
        console.log("action default");
        throw new Error();
    }
  }, storedState);
  useEffect(() => setStoreState(state), [setStoreState, state]);

  return <Provider value={{ state, dispatch, config }}>{children}</Provider>;
};

export { store, StateProvider };
