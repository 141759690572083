import React, { useContext, useState } from "react";
import { InView } from 'react-intersection-observer';
import {
  SegmentAnchorText,
  SegmentContainer,
  SegmentVariantText,
  VideosList,
  VideosTitle,
  BrollContainer
} from "./styles";

import VideoCard from "../../Components/VideoCard/index";
import { store } from "../../store";

const RenderQueue = () => {
  const { config: {renderQueue} } = useContext(store);
  const checkAnchor = (item) => item ? "End" : "Beginning";
  const [videoCounter, setVideoCounter] = useState(16);

  const videosAll = [];
  renderQueue.forEach(({ id, text, segments, url, resultUrl, music, date, proportions }, index) => {
    if (index >= videoCounter) return;

    const segmentsView = segments.map(({ baseVideo, overlayVideo, overlayAlignEnd }, index) =>
      <SegmentContainer key={index}>
        <SegmentVariantText>{baseVideo.key}</SegmentVariantText>
        {/* FIXME probobly need to mark that this is collection of b-rolls */}
        <BrollContainer>
          {overlayVideo.map((video, index) => <SegmentVariantText className={'segment__b-roll'} key={index}>{video.key}</SegmentVariantText>)}
          <SegmentAnchorText>{checkAnchor(overlayAlignEnd)}</SegmentAnchorText>
        </BrollContainer>
      </SegmentContainer>
    );
    videosAll.push(
      <VideoCard
        key={index}
        id={id}
        segments={segmentsView}
        text={text}
        jobUrl={url}
        resultUrl={resultUrl}
        music={music}
        date={date}
        proportions={proportions}
      />
    );
  });
  return (
    <VideosList>
      {videosAll ? null : <VideosTitle>Today</VideosTitle>}
      {videosAll ? videosAll : null}
      <InView as="div" onChange={() => setVideoCounter(videoCounter + 4)}></InView>
    </VideosList>
  )
};

export default RenderQueue;
