import React, { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import { Header, UserMenu, Nav, UserMenuItem } from "./styles";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import { makeStyles } from "@material-ui/core/styles";
import MenuProjectName from "../MenuProjectName";
import { Auth } from "aws-amplify";
import { Avatar } from "@material-ui/core";
import { store } from "../../store";

const useStyles = makeStyles((theme) => ({
  appbar: {
    backgroundColor: "rgba(227, 227, 227, 0.8)",
    color: "#686868",
    boxShadow: "none",
  },
  small: {
    // avatar
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

const MenuBar = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [email, setEmail] = useState(null);
  const [name, setName] = useState(null);
  const { dispatch, config } = useContext(store);
  const open = Boolean(anchorEl);

  useEffect(() => {
    Auth.currentUserInfo()
      .then((info) => {
        setEmail(info.attributes.email);
        setName(info.attributes.name);
        const identities = JSON.parse(info.attributes.identities);
        const provider = identities[0].providerType;
        if (provider === "Facebook") {
          const picture = JSON.parse(info.attributes.picture);
          setAvatarUrl(picture.data.url);
        } else {
          setAvatarUrl(info.attributes.picture);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    dispatch({type: 'setInitialState'})
    config.resetConfig();
    Auth.signOut().then(handleClose);
  };

  return (
    <Header position="fixed" classes={{ root: classes.appbar }}>
      <MenuProjectName />
      {Object.keys(config.currentProject).length === 0 ? null : (
        <Nav>
          <ul>
            <li>
              <NavLink exact to="/renderqueue" activeClassName="selected">
                Render queue
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/editor" activeClassName="selected">
                Editor
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/media" activeClassName="selected">
                Media
              </NavLink>
            </li>
          </ul>
        </Nav>
      )}
      <UserMenu>
        <IconButton
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          <Avatar alt={name} src={avatarUrl} className={classes.small}>
            {name && name.charAt(0)}
          </Avatar>
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          style={{ transformOrigin: "center top" }}
        >
          <UserMenuItem onClick={handleClose}>{email}</UserMenuItem>
          <UserMenuItem className={"menu__logout"} onClick={handleSignOut}>
            Sign out
          </UserMenuItem>
        </Menu>
      </UserMenu>
    </Header>
  );
};
export default MenuBar;
