import React, { useState } from 'react';
import { Image } from './styles';
import { InView } from 'react-intersection-observer';
import { useMediaUrl } from '../../particles/media';

export const ImageItem = ({ url, alt }) => {
  const [isInView, setInView] = useState(false);
  return (
    <InView as="div" className={"inview-element"} onChange={setInView} skip={isInView}>
      <Image src={isInView ? url : ""} alt={alt} />
    </InView>
  )
}

export const MediaImageItem = ({ mediaKey, isPrivate }) => {
  const url = useMediaUrl(mediaKey, isPrivate);
  return <ImageItem url={url} alt={mediaKey} />
}

export default MediaImageItem;
