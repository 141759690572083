import React, { useContext, useEffect, useRef, useState } from "react";
import {
  DownloadText,
  ErrorIcon,
  ErrorText,
  ImageContainer,
  RenderingText,
  TextContainer,
  VideoContainer,
  VideoCover,
} from "./styles";
import {SegmentVariantText} from '../../pages/renderqueue/styles'
import { store } from "../../store";
import Loader from '../Loader/index'
import { VideoItem } from '../../Components/MediaVideo/index'
import { getDate, getFilename } from '../../helpers'
import RemoveModal from '../RemoveModal/index';
import ModalComponent from "../Modal/index";
import {useJobPubSub} from '../../particles/pubsub';
import { Auth } from "aws-amplify";

const VideoCard = ({ id, jobUrl, segments, text, resultUrl, music, date, proportions  }) => {
  const {state, config: {renderQueue, setRenderQueue, scheduledJobs, refreshJobFiles} } = useContext(store);
  const [loaded, setLoaded] = useState(false);
  const [videoUrl, setVideoUrl] = useState(resultUrl);
  const [error, setError] = useState(false);
  const timerRef = useRef(null);
  const itemDate = getDate(date);
  const [removeOpen, setRemoveOpen] = useState(false);


  //TODO: nie dokonca mnie przekonuje ze ta logika aktualizacji stanu powinna byc tutaj 
  //... generalnie odswiezanie danych powinno być gdzies na zewnatrz w serwisie który te dane utrzeymuje
  // a card view powinien byc tylko glupim widokiem kotry tylko pokazuje dane i przechwytuje input, ale danych jako tako nie modyfikuje

  // subscribe only if no videoUrl
  const jobStatus = useJobPubSub(videoUrl ? null : id ); 

  const updateJobStatus = ({ code, url }) => {
    if (code === 200) {
      setVideoUrl(url);
      clearTimeout(timerRef.current)
    } else if (code !== 202) {
      setError(true);
      clearTimeout(timerRef.current)
    }
  }
  
  useEffect(() => {   
    if (resultUrl) setVideoUrl(resultUrl);
  }, [ resultUrl]);

  useEffect(() => {
    if(videoUrl) setRenderQueue(renderQueue.map( job => job.id !== id ? job : {...job, resultUrl: videoUrl }));
  }, [videoUrl, id])

  useEffect(()=> {
    if (jobStatus) updateJobStatus(jobStatus);
  },[jobStatus])

  useEffect(() => {
    if (resultUrl) return;
    const refreshJobStatus = async () => {
      const token = (await Auth.currentSession()).getIdToken().getJwtToken();
      const data = await fetch(jobUrl, {headers: {'Authorization': token}}).then(async (response) => {
        const { status: code } = response;
        const json = await response.json();
        return {
          code, ...json
        }
      });
      updateJobStatus(data);
    };
    refreshJobStatus();
    clearTimeout(timerRef.current)
    timerRef.current = setTimeout(refreshJobStatus, 60000);
    return () => clearTimeout(timerRef.current);
  }, [jobUrl, resultUrl]);

  const handleRemoveFile = (file) => {
    setRenderQueue(renderQueue.filter( (item) => item.id !== id))
    setRemoveOpen(false)
  }

  const handleRetry = async (item) => {
    console.log('retry', item)
    const job = await refreshJobFiles(renderQueue.find((item) => item.id === id));
    setError(false);
    setRenderQueue(renderQueue.filter( (item) => item.id !== id));
    scheduledJobs([job]);
  }
  return (
    <>
    <VideoContainer>
      <ImageContainer bgColor={error ? '#FFEAEA' : '#e5e5e5'} proportion={proportions || '16:9'}>
        <VideoCover isFirst={ !resultUrl ? true : false} />
        {error || !videoUrl ? null : <VideoItem url={videoUrl} setLoaded={setLoaded} posterUrl={videoUrl.replace('.mp4', '-video-thumbnail.jpg')}/>}
        {error ? <ErrorIcon/> : <Loader isLoading={loaded}/>}
      </ImageContainer>
      <TextContainer>{segments ? segments : null}</TextContainer>
      <SegmentVariantText className={'segment__text'}>{ text || 'No text'}</SegmentVariantText>
      <SegmentVariantText className={'segment__audio'}>{ music ? music.key : 'No music track'}</SegmentVariantText>
      <SegmentVariantText className={'segment__date'}>
        {itemDate.day} {itemDate.month} {itemDate.year} at {itemDate.hour}:{itemDate.minutes}
      </SegmentVariantText>
      <TextContainer className={"bottom__text"}>

        {error ? <><ErrorText>Unexpected error</ErrorText><RenderingText className={"bottom__action-btn"} onClick={() => handleRetry(jobUrl)}>Retry</RenderingText><RenderingText className={"bottom__action-btn"} onClick={() => setRemoveOpen(true)}>Remove</RenderingText></> : videoUrl ? (
          <><DownloadText href={videoUrl}>Download</DownloadText><RenderingText className={"bottom__action-btn"} onClick={() => setRemoveOpen(true)}>Remove</RenderingText></>
        ) : (
          <RenderingText>Rendering...</RenderingText>
        )}
      </TextContainer>
    </VideoContainer>
    <ModalComponent
       open={removeOpen}
       close={() => setRemoveOpen(false)}
       >
        <RemoveModal item={{key: getFilename(videoUrl) || ''}} close={setRemoveOpen} remove={handleRemoveFile} />
      </ModalComponent>
    </>
  );
};

export default VideoCard;
