import React, { useContext } from "react";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { store } from "../../store";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '13px',
    fontWeight: 400,
    color: '#000000',
    border: '1px solid #a7a7a7',
    borderRadius: '4px',
    padding: '0 16px',
    paddingBottom: '0 !important',
    marginBottom: '10px',
    width: '100%',
    maxWidth: '300px',
    "&:before": {
      display: 'none',
    },
    "&:after": {
      display: 'none',
    },
    ".select-input--error &" : {
      borderColor: 'red',
    }
  },
  input: {
    padding: '0 !important',
    fontSize: '15px',
    fontWeight: '400',
    lineHeight: '18px',
    height: '42px',
  }
}));

const checkIfOptionExists = (options, defaultValue, config, variantIndex, segmentIndex, brollIndex, audioIndex) => {
  const { videoVariants, setVideoVariants, audioVariants, setAudioVariants} = config;
  const isDefaultZero = defaultValue === 0 ? defaultValue : defaultValue.value;
  if (isDefaultZero !== 0) {
    const optionExists = options.find((item) => {if( item.valueName === defaultValue.key && item.private === defaultValue.private ) {return true} else {return false}})

    if(optionExists === undefined ) {
      const newVariants = [...videoVariants];
      if(variantIndex >= 0) {
        
        if(!isNaN(brollIndex)) {
          newVariants[variantIndex][segmentIndex].segment.b[brollIndex] = {value: 0};
         setVideoVariants(newVariants)
          return;
        }
        newVariants[variantIndex][segmentIndex].segment.a = {value: 0};
        setVideoVariants(newVariants)
        return;
      }
      const newAudioVariants = [...audioVariants];
      newAudioVariants.splice(audioIndex,1);
      setAudioVariants(newAudioVariants);
    }
    return optionExists ? true : false;

  }
}

const AutocompleteSelect = ({id, options, defaultValue = 1, click, className, isAudio = false, isError, variantIndex, segmentIndex, brollIndex, audioIndex = false }) => {
  const classes = useStyles();
  const { config} = useContext(store);
  return (
    <Autocomplete
      size="small"
      id={id}
      options={options.sort((a, b) => (b.group < a.group ? 1 : b.group > a.group ? -1 : 0))}
      groupBy={(option) => option.group}
      getOptionLabel={(option) => {
        if (isNaN(option) || isAudio ) {
          return option.valueName ? option.valueName : option} else {
          return 'Choose..'
      } }}
      getOptionSelected={(option, defaultValue) => option.valueName === defaultValue.key}
      renderInput={(params) => <TextField data-private={defaultValue.private} {...params} />}
      onChange={(e, option) => click(e, option)}
      className={`select-input select-input--segment ${className} ${isError ? 'select-input--error' : ''}`}
      value={checkIfOptionExists(options, defaultValue, config, variantIndex, segmentIndex, brollIndex, audioIndex) ? defaultValue.key: 0}
      classes={{
        inputRoot: classes.root,
        input: classes.input,
      }}
    />
  );
};

export default AutocompleteSelect;
