import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { css, Global } from "@emotion/react";
import { StateProvider } from "./store";
import Amplify from 'aws-amplify';
import awsConfig from './aws-exports';
import { matchUri } from './helpers';
import LogRocket from 'logrocket';

// split uris and replace by current one
awsConfig.oauth.redirectSignIn = matchUri(awsConfig.oauth.redirectSignIn);
awsConfig.oauth.redirectSignOut = matchUri(awsConfig.oauth.redirectSignOut);

if (process.env.REACT_APP_ENV === 'prod') {
  awsConfig.oauth.domain = 'auth.canexplode.com';
}

Amplify.configure(awsConfig);

LogRocket.init('bt7wt5/can-explode-platform');

const GlobalStyles = `
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&display=swap');
  html,
  #root {
    height: 100%;
  }
  body {
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background-color: #E5E5E5;
    height: 100%;
    margin: 0;
    overflow-y: scroll !important;
    padding-right: 0 !important;
  }
  * {
    box-sizing: border-box;
  }
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  img {
    max-width: 100%;
  }
`;

ReactDOM.render(
  <>
    <Global
      styles={css`
        ${GlobalStyles}
      `}
    />
    <StateProvider env={process.env.REACT_APP_ENV}>
      <App/>
    </StateProvider>
  </>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
