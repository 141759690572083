import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Container } from "./styles";
import BeeRollFormView from "./component/formview/index";
import Loader from "../../Components/Loader/index";
import BottomBar from "../../Components/BottomBar";
import Button from "@material-ui/core/Button";
import { store } from "../../store";

import { getFileByKey } from "../../helpers";

export const getFileName = (item) => {
  if (!item) return "None";
  const lastSlash = item.lastIndexOf("/");
  const lastDot = item.lastIndexOf(".");
  const withoutLastDot = item.substring(lastSlash + 1, lastDot);
  const searchText = withoutLastDot.indexOf('?')
  return `${withoutLastDot.substring(0, searchText)}`;
};

const BeeRollForm = () => {
  const { dispatch, state, config } = useContext(store);
  const [loadingState, setLoadingState] = useState(false);
  const [renderedVideos, setRenderedVideos] = useState(0);
  const [enableRender, setEnableRender] = useState(false);
  const enableReset = config.videoVariants.length || config.textVariants.length;
  const history = useHistory();

  useEffect(() => {
    const videoClips = state.mediaList.filter(item => item.type === 'video').map((item, index) => {
      return { value: index + 1, valueName: item.key, duration: item.duration, private: item.private };
    });
    dispatch({ type: "setVideoClips", videoClips });

    const audioClips = state.mediaList.filter(item => item.type === 'audio').map((item, index) => {
      return { value: index, valueName: item.key, private: item.private };
    });
    dispatch({ type: "setAudioClips", audioClips });
  }, [dispatch, state.mediaList]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch({ type: "setFormSubmitted", item: true });
    // eslint-disable-next-line
    let isDisabled = false;
    const formEl = e.target.elements;
    e.target.checkValidity();
    let fontSettings = config.settingsVariants.font;
    let overlaySettings = {
      overlayPosition: config.settingsVariants.position,
      overlayColor: config.settingsVariants.overlayColor,
      overlayFadeIn: config.settingsVariants.fadeIn,
      overlayFadeOut: config.settingsVariants.fadeOut
    };
    let texts = [];
    let allVideoVariants = {};
    let audioVariants = [];

    for (let i = 0; i < formEl.length; i++) {
      if (formEl[i].id.includes("text")) {
        texts.push(formEl[i].value);
      }

      if (
        (formEl[i] && formEl[i].id.includes("audioVariant"))
      ) {
        const audioId = state.audioClips.find((item) => item.valueName === formEl[i].value)
        audioVariants.push(audioId ? audioId.value : undefined);
      }

      const videoVariants = document.querySelectorAll(".single-variant__video");
      if (videoVariants) {
        for (let i = 0; i < videoVariants.length; i++) {
          const variantSegments = videoVariants[i].querySelectorAll(
            ".single-segment"
          );
          allVideoVariants[videoVariants[i].id] = [];

          variantSegments.forEach((item) => {
            let options = {
              baseVideo: "",
              overlayVideo: [],
              overlayAlignEnd: "",
              variantName: "",
              duration: 0,
            };

            const checkPrivate = (itemInput) => {
              const isPrivate = itemInput.closest('[data-private]') ? itemInput.closest('[data-private]').getAttribute('data-private') : undefined;
              return !!isPrivate && isPrivate !== "false";
            }
            const segmentInputs = item.querySelectorAll("input");
            segmentInputs.forEach((itemInput) => {

              if (itemInput.id.includes("VariantA")) {
                if (parseInt(itemInput.value) === 0) {
                  // eslint-disable-next-line no-loop-func
                  isDisabled = true;
                  const inputParent = itemInput.parentNode;
                  inputParent.classList.add("select-input--error");
                }
                const video = getFileByKey(itemInput.value, checkPrivate(itemInput), state.mediaList);
                if (!video) {
                  console.error("can find VariantA for", itemInput.value, checkPrivate(itemInput), state.mediaList);
                }
                options.baseVideo = video ? { key: video.key, private: video.private } : undefined;
                options.variantName = `A${itemInput.value}`;
                options.duration = video.duration;
              } else if (
                itemInput.id.includes("VariantB")
              ) {
                const video = getFileByKey(itemInput.value, checkPrivate(itemInput), state.mediaList);
                if (video) {
                  options.overlayVideo.push({ key: video.key, private: video.private });
                  options.variantName = `${options.variantName}B${itemInput.value}`;
                }
              } else if (
                itemInput.previousElementSibling.id.includes("Anchor")
              ) {
                options.overlayAlignEnd = parseInt(itemInput.value) !== 1;
              }
            });
            allVideoVariants[videoVariants[i].id].push(options);
          });
        }
      }
    }

    const data = {
      texts,
      fontSettings,
      overlaySettings,
      allVideoVariants,
      audioVariants,
      jobs: [],
    };

    const singleJobData = async (text, music, videoIndex) => {
      const segments = data.allVideoVariants["videoVariant" + [videoIndex]];
      const length = segments
        .map((segment) => segment.duration)
        .reduce((sum, val) => sum + val, 0);
      const width = config.settingsVariants.proportions.resolution.width;
      const height = config.settingsVariants.proportions.resolution.height;
      const proportions = config.settingsVariants.proportions.valueName;

      const resolved = await config.getResolvedUrls(segments, music);

      const job = {
        text,
        music,
        segments,
        proportions,
        request: config.buildJobRequest({ ...resolved, text, height, width, length }),
      };
      data.jobs.push(job);
      console.log("job request:", job);
    }

    console.log({ audioVariants: data.audioVariants });

    for (let i = 0; i < Object.keys(data.allVideoVariants).length; i++) {
      for (let t = 0; t < data.texts.length; t++) {
        for (let a = 0; a < data.audioVariants.length; a++) {
          const dataAudioVariant = state.audioClips.find(({ value }) => value === parseInt(data.audioVariants[a]))
          console.log(data.audioVariants[a], dataAudioVariant);
          await singleJobData(data.texts[t], dataAudioVariant ? { key: dataAudioVariant.valueName, private: dataAudioVariant.private } : undefined, i)
        }
      }
    }
    // eslint-disable-next-line
    if (!isDisabled) {
      setLoadingState(true);
      await config.scheduledJobs(data.jobs);
      history.push("/renderqueue");
    }
  };
  return (
    <Container>
      <form onSubmit={(e) => handleSubmit(e)}>
        <BottomBar>
          <Button
            className={`btn btn__transparent ${!enableReset ? "btn--inactive" : ""
              }`}
            disabled={!enableReset}
            onClick={config.reset}
          >
            Reset
          </Button>
          <Button
            className={"btn btn__transparent"}
            onClick={() => dispatch({ type: "setTextModalOpen", isOpen: true })}
          >
            Settings
          </Button>
          <Button
            type="submit"
            className={`btn ${!enableRender ? "btn--inactive" : ""} ${loadingState ? 'btn--loading' : ''}`}
            disabled={!enableRender}
          >
            {loadingState ? (
              <Loader isLoading={true} borderColor={"#fff"} />
            ) : (
              `Render ${renderedVideos} videos`
            )}
          </Button>
        </BottomBar>
        <BeeRollFormView
          renderedVideos={setRenderedVideos}
          loadingState={loadingState}
          enableRender={setEnableRender}
        />
      </form>
    </Container>
  );
};

export default BeeRollForm;
