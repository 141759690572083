import React, { useContext, useState } from "react";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import { MediaVideoItem } from "../MediaVideo";
import { MediaImageItem } from "../MediaImage/index.js";
import { MediaAudioItem } from "../MediaAudio";
import { CardTitle, RemoveItem } from "./styles";
import ModalComponent from "../Modal/index";
import { store } from "../../store";
import { Storage } from "aws-amplify";
import { getVideoThumbFileKey } from "../../helpers";
import RemoveModal from "../RemoveModal/index";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "16px",
    margin: "18px",
    maxWidth: "288px",
  },
  media: {},
}));

const MediaCard = ({ item }) => {
  const classes = useStyles();
  const { dispatch } = useContext(store);
  const [removeOpen, setRemoveOpen] = useState(false);

  const handleRemoveItem = async (item) => {
    try {
      const level = item.private ? "private" : "public";
      await Storage.remove(item.key, { level });
      if (item.type === "video") {
        await Storage.remove(getVideoThumbFileKey(item.key), { level });
      }
      setRemoveOpen(false);
      dispatch({ type: "removeMedia", item });
    } catch (e) {
      // TODO: display error on UI
      console.error(e);
    }
  };

  const handleModalRemove = () => {
    setRemoveOpen(true);
  };

  return (
    <>
      <Card className={classes.root}>
        {item.type === "video" ? <MediaVideoItem isPrivate={item.private} mediaKey={item.key} posterKey={getVideoThumbFileKey(item.key)} /> : null}
        {item.type === "image" ? <MediaImageItem isPrivate={item.private} mediaKey={item.key} /> : null}
        {item.type === "audio" ? <MediaAudioItem isPrivate={item.private} mediaKey={item.key} /> : null}
        <CardTitle>{item.key}</CardTitle>
        <CardTitle>{item.duration}</CardTitle>
        {item.size && ( // zero size means demo asset which can not be removed
          <RemoveItem onClick={() => handleModalRemove(item)}>
            Remove
          </RemoveItem>
        )}
      </Card>
      <ModalComponent open={removeOpen} close={() => setRemoveOpen(false)}>
        <RemoveModal
          item={item}
          close={setRemoveOpen}
          remove={handleRemoveItem}
          classes={classes}
        />
      </ModalComponent>
    </>
  );
};

export default MediaCard;
