import styled from '@emotion/styled';

export const ContentContainer = styled.div`
  padding: 16px 24px 24px;
  border-top: 1px solid #e9e9e9;
  display: flex;
  justify-content: flex-end;

  .btn {
    border-radius: 4px;
    overflow: hidden;
  }

  .btn__add-next button {
    background-color: transparent;
    border: 0;
    outline: none;
    font-size: 15px;
    line-height: 18px;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    color: #2F80ED;
    padding: 0;
    &:disabled {
      opacity: .3;
    }
  }

  .btn__upload-now {
    margin-left: auto;

    button {
      &:disabled {
        color: #333;
        background-color: #EFEFEF;
      }
    }
  }

  .btn__big button {
    background-color: #2F80ED;
    color: #fff;
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    text-align: right;
    padding: 12px 24px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
    border: 0;
    margin-right: 16px;
    position: relative;
    height: 43px;
    min-width: 72px;
    outline: none;
    font-family: 'Roboto', sans-serif;
    margin-right: 0;
  }
`