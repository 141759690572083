/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:23b1f3a6-b6fc-4c2c-9849-b0e5d9c6809a",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_7SGlxPUWB",
    "aws_user_pools_web_client_id": "afnri9r6ut25sl74euhgsm6s0",
    "oauth": {
        "domain": "videocreationflow2a1e5c03-2a1e5c03-prod.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://dev.d38z46mlks6bol.amplifyapp.com/,https://master.d38z46mlks6bol.amplifyapp.com/,https://platform.canexplode.com/,https://www.platform.canexplode.com/",
        "redirectSignOut": "http://localhost:3000/,https://dev.d38z46mlks6bol.amplifyapp.com/,https://master.d38z46mlks6bol.amplifyapp.com/,https://platform.canexplode.com/,https://www.platform.canexplode.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://nj92zty6xc.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "api",
            "endpoint": "https://45a392mqri.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        }
    ],
    "aws_user_files_s3_bucket": "videocreationflowbucket-6bxg8dhp170743-prod",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
