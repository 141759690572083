import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

const Error1Anim = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
`

const Error2Anim = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-45deg);
  }
`

export const VideoContainer = styled.div`
  background-color: #fff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  padding: 16px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  max-width: 288px;
  width: 100%;
  margin: 0 16px 32px;
  flex-flow: row wrap;
`

export const ImageContainer = styled.div`
  background-color: ${props => props.bgColor};
  line-height: 0;
  margin-bottom: 6px;
  position: relative;
  width: 100%;
  height: ${props => props.proportion === '16:9' ? '144px' : '273px'};
  max-width: ${props => props.proportion === '16:9' ? '355px' : '154px'};

  img, video {
    max-width: 100%;
    line-height: 0;
    position: relative;
    z-index: 2;
  }
`

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row wrap;
  width: 100%;
  margin-top: 10px;
  &.bottom__text {
    margin-top: 14px;
  }
`

export const VideoTitle = styled.p`
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  color: #000;
  text-align: center;
`

export const RenderingText = styled.p`
  text-align: center;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #000;
  &.bottom__action-btn {
    color: #2F80ED;
    cursor: pointer;
    margin: 0 8px;
  }
`

export const ErrorText = styled.p`
  text-align: center;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #FF0000;
`

export const DownloadText = styled.a`
  text-align: center;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #2F80ED;
  text-decoration: none;
`

export const Video = styled.video`
  width: 100%;
`

export const ErrorIcon = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 32px;
  height: 32px;
  margin-left: -16px;
  margin-top: -16px;

  &:after,
  &:before {
    content: '';
    width: 42px;
    height: 5px;
    border-radius: 3px;
    background-color: #FFB9B9;
    position: absolute;
    top: 13px;
    left: -5px;
  }

  &:after {
    transform: rotate(0);
    animation-name: ${Error1Anim};
    animation-duration: 1s;
    animation-fill-mode: forwards;
  }

  &:before {
    transform: rotate(0);
    animation-name: ${Error2Anim};
    animation-duration: 1s;
    animation-fill-mode: forwards;
  }
`

export const VideoCover = styled.div`
  background-color: #000000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 2;
  opacity: ${props => props.isFirst ? '0' : '1'};
  transition: opacity .2s ease-in-out;
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    border-top: 12px solid transparent;
    border-left: 20px solid #fff;
    border-bottom: 12px solid transparent;
    transform: translate(-50%, -50%);
  }
`
