import styled from "@emotion/styled";

export const BottomBarContainer= styled.div`
position: fixed;
z-index:1;
bottom: 0;
left: 0;
right: 0;
width: 100%;
background-color: rgba(220, 220, 220, 0.8);
backdrop-filter: blur(32px);
padding: 12px 24px;
display:flex;
align-items: center;
justify-content: flex-end;
max-width: 100%;
a {
  &.btn {
    text-decoration: none;
    &:hover {
      color: #fff;
    }
  }
}
.btn {
  background-color: #2F80ED;
  font-size: 16px;
  color: #fff;
  line-height: 20px;
  font-weight: 500;
  padding: 14px 22px;
  text-transform: initial;
  margin-left: 16px;
  &__transparent {
    background-color: transparent;
    color: #333;
    font-weight: 400;
  }
  &:hover {
    color: #333;
  }
  &[disabled] {
    pointer-events: none;
    cursor: default;
    opacity: 0.3;
  }
}
`