import styled from '@emotion/styled';

export const Submenu = styled.div`
  position: absolute;
  left: 24px;
  display:flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row wrap;
  &.menu--relative {
    position: relative;
  }
`

export const ProjectName = styled.p`
  display: inline-flex;
  font-weight: 500;
  font-size: 16px;
  color: #333;
`

