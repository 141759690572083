import styled from '@emotion/styled';
import Drawer from '@material-ui/core/Drawer';

export const DrawerContainer = styled(Drawer)`

  &.drawer {
    .MuiDrawer-paper {
      padding-top: 12px;
      background-color: rgba(237, 237, 237, 0.85);
      backdrop-filter: blur(30px);  
      width: 100%;
      max-width: 288px;
      .menu--relative {
        margin-bottom: 44px;
        left: auto;
        padding-left: 24px;
      }
    }
  }
`

export const CreateNewBtn = styled.div`
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 54px;
  cursor: pointer;
  p {
    font-weight: 500;
    color: #2F80ED;
    font-size: 16px;
    line-height: 48px;
  }
`
