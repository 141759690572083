import styled from "@emotion/styled";

export const MediaContainer = styled.div`
  display:flex;
  align-items: center;
  justify-content: center;
  flex-flow:row wrap;
  flex: 1 0 100%;
  width: 100%;
  padding-bottom: 88px;
  padding-top: 26px;
`

export const SectionContainer = styled.div`
  display:flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 auto 14px;
  width: 100%;
  max-width: 1282px;
  > div {
    width: 100%;
  }
`

export const SectionTitle = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #333;
  display:block;
  width: 100%;
  padding-left: 18px;
  margin-bottom: 2px;
`
