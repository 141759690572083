import styled from "@emotion/styled";

export const VideosList = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: row wrap;
  padding-top: 56px;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
`

export const SegmentContainer = styled.div`
  display: inline-flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: row wrap;
  max-width: 100%;
  width: 100%;
  margin-bottom: 8px;
`;

export const SegmentVariantText = styled.p`
  font-size: 13px;
  line-height: 18px;
  color: #686868;
  text-align: left;
  text-transform: unset;
  width: 100%;
  &.segment__audio {
    margin-bottom: 8px;
  }
`

export const SegmentAnchorText = styled(SegmentVariantText)`
  opacity: 0.5;
  text-transform: unset;
  width: 100%;
`

export const VideosTitle = styled.p`
  display:block;
  width: 100%;
  text-align: left;
  font-size: 20px;
  line-height: 24px;
  color: #333;
  margin-bottom: 30px;
  padding-left: 16px;
  font-weight: 500;
`

export const BrollContainer = styled.div`
  padding-left: 14px;
  display:block;

`
