import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

const loaderAnim = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const LoaderContainer = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 24px;
  border-right: 2px solid ${props => props.borderColor ? props.borderColor : '#000'};
  border-left: 2px solid transparent;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
  animation-name: ${props => props.isLoading ? loaderAnim : 'none'};
  animation-duration: 1s;
  animation-iteration-count: infinite;
`;
