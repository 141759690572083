import React from 'react';
import { CloseButton} from '../../pages/editor/styles'
import { ModalClose, ModalTitle, ModalHeaderContainer } from './styles';

const ModalHeader = ({title, click}) => {

  return(
    <ModalHeaderContainer>
      <ModalTitle>{title}</ModalTitle>
      <ModalClose>
        <CloseButton onClick={() => click()} />
      </ModalClose>
    </ModalHeaderContainer>
  )
}

export default ModalHeader;
