import React from "react";
import ModalHeader from "../ModalHeader";
import Button from '@material-ui/core/Button';
import { ModalContent, ModalFileTitle, ModalBottom} from './styles';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  closeBtn: {
    fontSize: `16px`,
    fontWeight: '500',
    backgroundColor: '#2F80ED',
    color: "#fff",
    padding: '7px 24px',
    '&:hover': {
      color: '#333',
    }
  },
  removeBtn: {
    color: '#DD0000',
    fontSize: '16px',
    fontWeight: '600',
    padding: '7px 24px',
    backgroundColor: '#EFEFEF',
    marginRight: '10px',
  }
}));

const RemoveModal = React.forwardRef((props, ref) => {
  const classes = useStyles();
  return (
    <ModalContent ref={ref} tabIndex={-1} onKeyDown={(e) => { if(e.key === "Enter") {props.remove(props.item)}}}>
      <ModalHeader
        title={"Remove this file?"}
        click={() => props.close(false)}
      />
      <ModalFileTitle>{props.item.key}</ModalFileTitle>
      <ModalBottom>
        <Button size="large" className={classes.removeBtn} onClick={() => props.remove(props.item)}>
          Remove
        </Button>
        <Button size="large" className={classes.closeBtn} onClick={() => props.close(false)}>
          Cancel
        </Button>
      </ModalBottom>
    </ModalContent>
  );
});

export default RemoveModal;