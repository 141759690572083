import React from 'react';
import PropTypes from 'prop-types';
import { TitleContainer, TitleText } from './styles';
import { themeColor } from '../../particles/colors';

export const TitleTypes = {
  big: "Big",
  middle: "Middle",
  small: "Small",
}

const Title = ({ text, type, color = themeColor.white, className, fontSize, fontFamily }) => {
  return (
    <TitleContainer className={className}>
      <TitleText
        type={type}
        color={color}
        fontSize={fontSize}
        fontFamily={fontFamily}
      >
        {text}
      </TitleText>
    </TitleContainer>
  )
}

Title.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
}

export default Title;
