import styled from "@emotion/styled";

export const Half40 = styled.div`
  max-width: 40%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: row wrap;
  padding-left: 10px;
  padding-right: 22px;
  align-content: flex-start;
`

export const Half60 = styled.div`
  max-width: 60%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: row wrap;
  padding-left: 22px;
  padding-right: 10px;
  align-content: flex-start;
`

export const Half80 = styled.div`
  max-width: 80%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: row wrap;
  padding-right: 10px;
  align-content: flex-start;
`;

export const Half20 = styled.div`
  max-width: 20%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: row wrap;
  padding-left: 10px;
  align-content: flex-start;

  &.slider-container {
    justify-content: center;

    .slider {
      max-height: 146px;
      width: 6px;

      .MuiSlider-rail,
      .MuiSlider-track {
        width: 6px;
        background-color: #797979;
        border-radius: 4px;
      }

      .MuiSlider-thumb {
        width: 16px;
        height: 16px;
        background-color: #797979;
      }
    }

    .slider-text {
      font-size: 16px;
      color: #000;
      line-height: 19px;
      margin-top: 16px;
      width: 100%;
      padding-right: 0;
      text-align: center;
    }
  }
`;

export const FullContent = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: row wrap;
  padding-left: 10px;
  padding-right: 10px;

  .input-label {
    width: 158px;
  }

  &.overlay-content {
    align-items: stretch;
  }
`;

export const Subtitle = styled.div`
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #000;
  margin-bottom: 26px;
`;
