import styled from '@emotion/styled';


export const CreateProjectContainer = styled.div`
  background-color: #fff;
  width: 100%;
`

export const ModalContent = styled.div`
  display:flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: row wrap;
  margin-top: -16px;
  margin-bottom: 16px;
  padding: 0 24px;
`
