import React, { useContext, useEffect, useState } from 'react';
import {store} from '../../store'
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from '@material-ui/icons/Close';
import { Submenu, ProjectName } from './styles';

const MenuProjectName = ({className}) => {
  const globalState = useContext(store);
  const {state, dispatch, config: {currentProject, projects} } = globalState;
  const [currentProjectName , setCurrentProjectName] = useState(state.currentProject.name);
  const handleToggleDrawer = (isOpen) => {
    dispatch({ type: 'setDrawerOpen', item: !isOpen})
  }

  useEffect( () => {
    projects.map((item) => {
      if (item.id === currentProject) {
        setCurrentProjectName(item.name)
        dispatch({ type: "setCurrentProject", item: item})
      }
    })
  }, [currentProject, projects])
  return(
    <Submenu className={className}>
        <IconButton
          edge="start"
          onClick={() => handleToggleDrawer(state.drawerOpen)}
          color="inherit"
          aria-label="open drawer"
        >
          { state.drawerOpen ? <CloseIcon/> : <MenuIcon /> }
        </IconButton>
        <ProjectName>{ state.drawerOpen ? 'Choose a project' : currentProjectName}</ProjectName>
      </Submenu>
  )
}

export default MenuProjectName;
