import styled from "@emotion/styled";

export const ModalHeaderContainer = styled.div`
  display:flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #e9e9e9;
  width: 100%;
  flex: 1 0 100%;
  height: 64px;
  margin-bottom: 28px;
`

export const ModalTitle = styled.div`
  font-size :20px;
  line-height: 23px;
  font-weight: 500;
  color:#333;
  padding-left: 24px;
  width: 100%;
  flex: 1 0 100%;
`

export const ModalClose = styled.div`
  position: absolute;
  right: 30px;
`
