import React from 'react';
import PropTypes from 'prop-types';
import { ButtonContainer, ButtonTypeButton, TextTypeButton } from './styles';

export const ButtonTypes = {
  text: "text",
  button: "button"
}

const Button = (
  {
    children,
    type,
    click,
    className,
    disabled = false,
    blocked,
    typeButton = 'button'
  }
) => {
  return (
    <ButtonContainer className={className} blocked={blocked}>
      {type === ButtonTypes.text ?
        <TextTypeButton onClick={click}>{children}</TextTypeButton>
        :
        <ButtonTypeButton onClick={click}
                          disabled={disabled}
                          type={typeButton}>{children}</ButtonTypeButton>
      }
    </ButtonContainer>
  )
}

Button.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  click: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
}

export default Button;
