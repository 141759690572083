import styled from '@emotion/styled';
import { Modal as ModalCommon } from '@material-ui/core';

export const Modal = styled(ModalCommon)`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
`
export const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;
  max-width: ${ props => props.width ? props.width : '600'}px;
  flex: 1 0 100%;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  &:focus {
    outline: none;
  }

  form {
    background-color: #fff;
    flex: 1 0 100%;
    max-height: 100vh;
    overflow-y: auto;
  }
`;
