import styled from '@emotion/styled';
import AppBar from '@material-ui/core/AppBar';
import MenuItem from '@material-ui/core/MenuItem';

export const Header = styled(AppBar)`
  position: relative;
  width: 100%;
  height: 72px;
  display:flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-flow: row wrap;
  padding: 0 24px !important;
  backdrop-filter: blur(20px);
`

export const UserMenu = styled.div`
  position: absolute;
  right: 24px;
  display: flex
  align-items: center;
  justify-content: flex-end;
  flex-flow: row wrap;
`

export const Nav = styled.nav`
  display:flex;
  margin: 0 auto;
  ul {
    display:flex;
    align-items: center;
    justify-content: center;
    flex-flow: row wrap;
    li {
      list-style: none;
      margin: 0 11px;
      a {
        text-decoration: none;
        padding: 6px 12px;
        border-radius: 4px;
        display:flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 400;
        color: #333;
        transition: all .2s ease-in;
        overflow: hidden;
        border: 2px solid transparent;
        &:focus,
        &.selected {
          background-color: rgba(0,0,0, .09);
        }
        &.selected {
          font-weight: 500;
        }
      }
      &:hover {
        a {
          border: 2px solid rgba(0,0,0, .09);
          &.selected {
            border-color: transparent;
          }
        }
      }
    }
  }
`

export const UserMenuItem = styled(MenuItem)`
  font-size: 15px;
  font-weight: 500;
  height: 50px;

  &.menu__logout {
    color: #2F80ED;
    border-top: 1px solid #e9e9e9;
  }
`
