import React, { useContext, useEffect, useState } from "react";
/* eslint-disable no-unused-vars */
import {
  AnchoringTitle,
  CloseButton,
  Container,
  ContentContainer,
  OptionContainer,
  RollTitle,
  SegmentTitles,
  SingleSegment,
  SingleVariant,
  VariantTitle,
  AddBRoll,
  VariantBContainer,
  VariantsBContainer,
  AudioVariantsContainer,
} from "../../styles";
import Button, { ButtonTypes } from "../../../../Components/Button";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import SelectInput from "../../../../Components/Select";
import ModalComponent from "../../../../Components/Modal";
import Settings from "../settings/index";
import { settingsValues, store } from "../../../../store";
import { reloadMedia } from '../../../../helpers'
import AutocompleteSelect from "../../../../Components/AutocompleteSelect";

const BeeRollFormView = ({ renderedVideos, loadingState, enableRender }) => {
  const [isNoText, setIsNoText] = useState(false);
  const [isNoAudio, setIsNoAudio] = useState(false);
  const [textVariantsViews, setTextVariantsViews] = useState();
  const [audioVariantsViews, setAudioVariantsViews] = useState();
  const [isARollError, setIsARollError] = useState([]);
  const globalStore = useContext(store);
  const {
    state, dispatch, config: {
      textVariants, setTextVariants,
      videoVariants, setVideoVariants,
      audioVariants, setAudioVariants,
      settingsVariants, setSettingsVariants,
      currentProject
    }
  } = globalStore;
  const audioClips = state.audioClips;

  useEffect(() => {
    const audioCount = audioVariants.length === 0 ? 1 : audioVariants.length
    renderedVideos(videoVariants.length * textVariants.length * audioCount);
    enableRender(isARollError.includes(0) ? false : videoVariants.length * textVariants.length * audioVariants.length > 0);
  }, [videoVariants, textVariants, renderedVideos, enableRender, audioVariants, isARollError]);

  useEffect(() => {
    setIsARollError([])
    videoVariants.forEach((item) => {
      for (let i = 0; i < item.length; i++) {
        if(item[i].segment.a === 0 || item[i].segment.a === undefined) {
          setIsARollError(isARollError => [...isARollError, 0 ]);
        } else {
          setIsARollError(isARollError => [...isARollError, 1 ]);
        }
      }
    } )
  }, [videoVariants])

  useEffect(() => {
    reloadMedia(state, dispatch);
  }, [])

  useEffect(() => {
    const newSettings = JSON.stringify(settingsValues);
    if (settingsVariants.length === 0) {
      setSettingsVariants(JSON.parse(newSettings));
    }
  }, [currentProject])

  useEffect(() => {
    let textVariantViews = [];
    setIsNoText(false)
    if (textVariants) {
      for (let i = 0; i < textVariants.length; i++) {
        if (textVariants[i].input === false) {
          setIsNoText(true)
        }
        textVariantViews.push(
          <SingleVariant
            className={`text-variants ${textVariants[i].input ? '' : 'text-variants--no-text'}`}
            key={i}
            loadingState={loadingState}
          >
            <TextField
              disableunderline={"true"}
              id={`text${i}`}
              classes={{ root: "form-input" }}
              onBlur={(e) => handleTextChange(e, i)}
              defaultValue={textVariants[i].text}
              inputProps={{
                maxLength: 16,
              }}
            />
            {textVariants[i].input ? null : <p>No Text</p>}
            <CloseButton onClick={() => handleRemoveText(i)} />
          </SingleVariant>
        );
      }
    }
    setTextVariantsViews(textVariantViews)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textVariants, currentProject])

  useEffect(() => {
    let audioVariantViews = [];
    const options = state.audioClips.map((option) => {
      return {
        group: option.private ? 'My Uploads' : 'Shared',
        ...option,
      };
    });
    setIsNoAudio(false);

    if (audioVariants) {
      for (let i = 0; i < audioVariants.length; i++) {
        if (audioVariants[i] === "") {
          setIsNoAudio(true);
        }
        audioVariantViews.push(
          <SingleVariant
            className={`text-variants ${audioVariants[i] !== "" ? '' : 'text-variants--no-audio'}`}
            key={i}
            loadingState={loadingState}
          >
            {audioVariants[i] !== "" ?
              <AutocompleteSelect 
                id={`audioVariantA${i}`}
                options={options}
                click={(e, option) => handleAudioChange(e, option, i)}
                defaultValue={audioVariants[i]}
                audioIndex={i}
                className={`select-input--audio`}
                isAudio={true}
              />
              : <TextField
                disableunderline={"true"}
                id={`audioVariantA${i}`}
                classes={{ root: "form-input" }}
                placeholder={"No music track"}
                disabled={true}
              />}
            <CloseButton onClick={() => handleRemoveAudio(i)} />
          </SingleVariant>
        );
      }
    }
    setAudioVariantsViews(audioVariantViews)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioVariants, currentProject, state.audioClips])

  const handleAddText = (input = true) => {
    if (input === false) {
      setIsNoText(true)
    }
    setTextVariants([...textVariants, { text: "", input }]);
  };

  const handleRemoveText = (index) => {
    let newText = [];
    for (let i = 0; i < textVariants.length; i++) {
      if (textVariants[i].input === false && i === index) {
        setIsNoText(false)
      }
      if (i !== index) {
        newText.push(textVariants[i]);
      }
    }
    setTextVariants(newText);
  };

  const handleVariantAChange = (e, option , i, index) => {
    let optionValue =  option === null ? 0 : {key: option.valueName, private: option.private};
    let newVideoVariants = [...videoVariants];
    newVideoVariants[i][index].segment = {
      a: optionValue,
      b: newVideoVariants[i][index].segment.b ? newVideoVariants[i][index].segment.b : [0],
    };
    setVideoVariants(newVideoVariants);
  };
  const handleVariantBChange = (e, option, i, index, indexB) => {
    let optionValue = option === null ? 0 : {key: option.valueName, private: option.private};
    let newVideoVariants = [...videoVariants];
    let videosB = [...newVideoVariants[i][index].segment.b];
    videosB[indexB] = optionValue;
    newVideoVariants[i][index].segment = {
      a: newVideoVariants[i][index].segment.a ? newVideoVariants[i][index].segment.a : 0,
      b: videosB,
    };
    setVideoVariants(newVideoVariants);
  };

  const handleAddVariantB = (e, i, index) => {
    let newVideoVariants = [...videoVariants];
    let videosB = [...newVideoVariants[i][index].segment.b, 0];
    newVideoVariants[i][index].segment = {
      a: newVideoVariants[i][index].segment.a ? newVideoVariants[i][index].segment.a : 0,
      b: videosB,
    };
    setVideoVariants(newVideoVariants);
  }

  const handleAnchorChange = (e, i, index) => {
    let newVideoVariants = [...videoVariants];
    newVideoVariants[i][index].anchor = e.target.value;
    setVideoVariants(newVideoVariants);
  };
  const handleAddVideoVariant = () => {
    let newVideoVariants = [...videoVariants];
    newVideoVariants.push([
      {
        segment: { a: 0, b: [0] },
        anchor: 1,
      },
    ]);
    setVideoVariants(newVideoVariants);
  };

  const handeRemoveVideoSegment = (i, index) => {
    let newSegments = [...videoVariants];
    newSegments[i].splice(index, 1);

    if (newSegments[i].length === 0) {
      newSegments.splice(i, 1);
    }
    setVideoVariants(newSegments);
  };

  const handeRemoveVideoB = (i, index, indexB) => {
    let newSegments = [...videoVariants];
    newSegments[i][index].segment.b.splice(indexB, 1);

    if (newSegments[i].length === 0) {
      newSegments.splice(i, 1);
    }
    setVideoVariants(newSegments);
  };

  const handleAddSegment = (index) => {
    let newSegments = [...videoVariants];
    if (newSegments[index].length < 5) {
      newSegments[index].push({
        segment: {a:0, b: [0] },
        anchor: 1,
      });
      setVideoVariants(newSegments);
    }
  };

  const handleTextChange = (e, index) => {
    const newTexts = [...textVariants];
    newTexts[index].text = e.target.value;
    setTextVariants(newTexts);
  };

  const handleAddAudio = (input = true) => {
    const audioClip = state.audioClips[0];
    if (input === false) {
      setIsNoAudio(true)
    }

    setAudioVariants([...audioVariants, !input ? "" : {key: audioClip.valueName, private: audioClip.private }  ]);
  }

  const handleAudioChange = (e, option, i, index) => {
    let newAudioVariants = [...audioVariants];
    newAudioVariants[i] = option ? { key: option.valueName, private: option.private} :  newAudioVariants[0];
    setAudioVariants(newAudioVariants);
  };

  const handleRemoveAudio = (index) => {
    let newAudio = [];
    for (let i = 0; i < audioVariants.length; i++) {
      if (audioVariants[i] === "" && i === index) {
        setIsNoAudio(false)
      }
      if (i !== index) {
        newAudio.push(audioVariants[i]);
      }
    }
    setAudioVariants(newAudio);
  };
  
  let videoVariantViews = [];

  for (let i = 0; i < videoVariants.length; i++) {
    const segments = [];
    // eslint-disable-next-line array-callback-return
    videoVariants[i].map((item, index) => {
      const options = state.videoClips.map((option) => {
        return {
          group: option.private ? 'My Uploads' : 'Shared',
          ...option,
        };
      });
      const videosBArray = videoVariants[i][index].segment.b;
      segments.push(
        <SingleSegment key={index} className={"single-segment"}>
          <AutocompleteSelect 
            id={`videoVariantA${index}`}
            options={options}
            click={(e, option) => handleVariantAChange(e, option, i, index)}
            defaultValue={videoVariants[i][index].segment.a}
            variantIndex={i}
            segmentIndex={index}
            isError={
              !!(parseInt(videoVariants[i][index].segment.a) === 0 || videoVariants[i][index].segment.a === undefined)
            }
          />
          <VariantsBContainer>
            {videosBArray ? videosBArray.map((item, indexB) => {
              return (
                <VariantBContainer key={indexB}>
                  <AutocompleteSelect 
                    id={`videoVariantB-${index}-${indexB}`}
                    options={options}
                    click={(e, option) => handleVariantBChange(e, option, i, index, indexB)}
                    defaultValue={item}
                    variantIndex={i}
                    segmentIndex={index}
                    brollIndex={indexB}
                    className={`select-input--segment-b`}
                  />
                  <CloseButton className={'remove-video-b'} onClick={() => handeRemoveVideoB(i, index, indexB)} />
                </VariantBContainer>
              )
            }) : null}

            <AddBRoll onClick={(e) => handleAddVariantB(e, i, index)}>Add B-roll</AddBRoll>
          </VariantsBContainer>
          <FormControl
            className="select-input__form-controll"
            disabled={videoVariants[i][index].segment.b === "none"}
          >
            <SelectInput
              id={`videoAnchor${index}`}
              values={[
                {
                  value: 1,
                  valueName: "Beginning",
                },
                {
                  value: 2,
                  valueName: "End",
                },
              ]}
              click={(e) => handleAnchorChange(e, i, index)}
              className={"select-input select-input--anchor"}
              defaultValue={videoVariants[i][index].anchor}
              readOnly={true}
            />
          </FormControl>

          <CloseButton className={"close-btn"} onClick={() => handeRemoveVideoSegment(i, index)} />
        </SingleSegment>
      );
    });
    if (segments.length !== 0) {
      videoVariantViews.push(
        <SingleVariant
          className={"single-variant__video"}
          id={`videoVariant${i}`}
          loadingState={loadingState}
          key={i}
        >
          <SegmentTitles>
            <RollTitle>A-Roll:</RollTitle>
            <RollTitle>B-Roll:</RollTitle>
            <AnchoringTitle>Anchoring:</AnchoringTitle>
          </SegmentTitles>
          {segments}
          {segments.length < 5 ? (
            <Button
              type={ButtonTypes.text}
              click={() => handleAddSegment(i)}
              className={"btn__add-segment"}
            >
              Add video segment
            </Button>
          ) : null}
        </SingleVariant>
      );
    }
  }

  return (
    <Container className="form-view">
      <ContentContainer>
        <OptionContainer className={"video-variants"}>
          <VariantTitle>Video Variants ({videoVariants.length})</VariantTitle>
          {videoVariantViews}
          {videoVariantViews.length < 5 ? (
            <Button
              type={ButtonTypes.text}
              click={() => handleAddVideoVariant()}
              className={"btn__video-variant btn__add-more"}
              blocked={loadingState}
            >
              +
            </Button>
          ) : null}
        </OptionContainer>
        <OptionContainer className={"text-variants"}>
          <VariantTitle>Text Variants ({textVariants.length})</VariantTitle>
          {textVariantsViews}
          {textVariantsViews && textVariantsViews.length < 5 ? (
            <>
              <Button
                type={ButtonTypes.text}
                click={() => handleAddText()}
                className="btn__text-variant btn__add-more"
                blocked={loadingState}
              >
                +
              </Button>
              {!isNoText ?
                <Button
                  type={ButtonTypes.text}
                  click={() => handleAddText(false)}
                  className="btn__text-variant btn__no-text"
                  blocked={loadingState}
                >
                  No text
                </Button>
                : null}
            </>
          ) : null}
          <AudioVariantsContainer>
            <VariantTitle>Audio Variants ({audioVariants.length})</VariantTitle>
            {audioVariantsViews}
            {audioVariantsViews && audioVariantsViews.length < 5 ? (
              <>
                <Button
                  type={ButtonTypes.text}
                  click={() => handleAddAudio()}
                  className="btn__text-variant btn__add-more"
                  blocked={loadingState}
                >
                  +
                </Button>
                {!isNoAudio ?
                  <Button
                    type={ButtonTypes.text}
                    click={() => handleAddAudio(false)}
                    className="btn__text-variant btn__no-text"
                    blocked={loadingState}
                  >
                    No music track
                  </Button>
                  : null}
              </>
            ) : null}
          </AudioVariantsContainer>
        </OptionContainer>
      </ContentContainer>
      <ModalComponent
        open={state.textModalOpen}
        close={() => dispatch({ type: "setTextModalOpen", isOpen: false })}
      >
        <Settings loadingState={loadingState} />
      </ModalComponent>
    </Container>
  );
};

export default BeeRollFormView;
